
import {
    MeetingConsumer,
    Constants,
    MeetingProvider,
    useMeeting,
  } from "@videosdk.live/react-sdk";
  import React, { useEffect, useMemo, useRef } from "react";
  import Hls from "hls.js";
  import { authToken } from "./api";
  
  const HLSPlayer = () => {
    const { hlsUrls, hlsState } = useMeeting();
  
    const playerRef = useRef(null);
  
    const hlsPlaybackHlsUrl = useMemo(() => hlsUrls.playbackHlsUrl, [hlsUrls]);
  
    useEffect(() => {
      if (Hls.isSupported()) {
        const hls = new Hls({
          capLevelToPlayerSize: true,
          maxLoadingDelay: 4,
          minAutoBitrate: 0,
          autoStartLoad: true,
          defaultAudioCodec: "mp4a.40.2",
        });
  
        let player = document.querySelector("#hlsPlayer");
  
        hls.loadSource(hlsPlaybackHlsUrl);
        hls.attachMedia(player);
      } else {
        if (typeof playerRef.current?.play === "function") {
          playerRef.current.src = hlsPlaybackHlsUrl;
          playerRef.current.play();
        }
      }
    }, [hlsPlaybackHlsUrl, hlsState]);
  
    return (
      <>
      <video
        ref={playerRef}
        id="hlsPlayer"
        autoPlay
        controls
        style={{ width: "100%", height: "70%" }}
        playsInline
        playing
        onError={(err) => console.log(err, "hls video error")}
      ></video>
      </>
    );
  };
  
  const ViewerScreenContainer = ({ meetingId }) => {
    console.log(meetingId, "meeting")
    return (
      <MeetingProvider
        token={authToken}
        config={{ meetingId, name: "C.V. Raman", mode: "VIEWER" }}
        joinWithoutUserInteraction
      >
        <MeetingConsumer>
          {({ hlsState }) =>
            hlsState === Constants.hlsEvents.HLS_PLAYABLE ? (
              <HLSPlayer />
            ) : (
              <p>Trwa dołączenie do live stream...</p>
            )
          }
        </MeetingConsumer>
      </MeetingProvider>
    );
  };
  
  export default ViewerScreenContainer;