import React, { useEffect, useRef, useState } from "react";
import ModalCategories from "../ModalCategories";
import ModalTags from "../ModalTags";
import { DEMO_POSTS_AUDIO } from "data/posts";
import { PostDataType } from "data/types";
import { DEMO_CATEGORIES, DEMO_TAGS } from "data/taxonomies";
import { DEMO_AUTHORS } from "data/authors";
import Pagination from "components/Pagination/Pagination";
import ButtonPrimary from "components/Button/ButtonPrimary";
import ArchiveFilterListBox from "components/ArchiveFilterListBox/ArchiveFilterListBox";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import NcImage from "components/NcImage/NcImageAds";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionSliderNewAuthors from "components/SectionSliderNewAthors/SectionSliderNewAuthors";
import Card16Podcast from "components/Card16Podcast/Card16Podcast";
import Card15Podcast from "components/Card15Podcast/Card15Podcast";
import { BACKEND_URL } from "../../../constants"
import SectionMagazine10 from "components/Sections/SectionMagazine10"
import { useParams } from "react-router-dom"
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
import SectionMagazine2 from "../../../components/Sections/SectionMagazine2"
import SectionMagazine4 from "components/Sections/SectionMagazine4"
import SectionMagazineSubCategory4 from "components/Sections/SectionMagazineSubCategory4"
import cogoToast from "cogo-toast"

const PageSubCategory = () => {
  const [data,setData] = useState([])
  const initialized = useRef(false);
  const params = useParams()

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      fetch(`${BACKEND_URL}/articles`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((response) => {
          if (!response.hasOwnProperty("error")) {
            // console.log(response)
           setData(response)
          }
        })
        .catch((error) => {
          console.error("error: ", error);
        })
        .finally(() => {
          console.log("finally")
        })
    }
  }, []);
  
  return (
    <div style={{paddingTop:"13px"}} className={`nc-PageArchiveAudio `}>

      <div className="container lg:pb-28 space-y-16 lg:space-y-28">
        <div>
          <div className="flex flex-col sm:items-center sm:justify-between sm:flex-row">
            <div className="block my-4 border-b w-full border-neutral-300 dark:border-neutral-500 sm:hidden"></div>
          </div>

          {/* LOOP ITEMS */}
          <SectionMagazineSubCategory4
            category={params.category}
            heading={ params.subcategory === "swiat" ? "ŚWIAT": params.subcategory.toLocaleUpperCase()}
            category_id={params.subcategory_id}
            subcategory_id={params.category_id}
            subcategory={params.subcategory}
            posts={data}
          />
        </div>
        {/* <div className="relative py-16">
          <BackgroundSection />
        </div> */}

      </div>
    </div>
  );
};

export default PageSubCategory;
