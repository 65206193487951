import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from "react-hook-form";
import cogoToast from "cogo-toast";
// import facebookSvg from "images/Facebook.svg";
// import twitterSvg from "images/Twitter.svg";
// import googleSvg from "images/Google.svg";
import Input from "components/Input/Input";
import { BACKEND_URL } from "../../../constants";
import axios from "axios";
import ButtonPrimary from "components/Button/ButtonPrimary";
import Heading2 from "components/Heading/Heading2";
import Datetime from 'react-datetime';
import 'moment/locale/pl';
import "react-datetime/css/react-datetime.css";
import Layout from "../layout";

const PageEditArticles = () => {
  const navigate = useNavigate()
  const params = useParams()
  const [date,setDate] = useState("")
  const [categories,setCategories] = useState([])
  const [categoryid, setCategoryId] = useState(0)
  const [subcategoryid, setSubCategoryId] = useState(0)
  const [subcategories,setSubCategories] = useState([])
  const [articles,setArticles] = useState([])
  let user = sessionStorage.getItem("user")
  const initialized = useRef(false);
  const {
    register,
    getValues,
    // formState: { errors },
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      title: "",
      description:"",
      long_description:"",
      image: "",
    },
  });
  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      fetch(`${BACKEND_URL}/article/${params?.id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((response) => {
          if (!response.hasOwnProperty("error")) {
           setArticles(response[0])
          }
        })
        .catch((error) => {
          console.error("error: ", error);
        })
        .finally(() => {
          console.log("finally")
        })
    }
  }, [JSON.parse(user)?.user.id, user]);


  useEffect(() => {
      fetch(`${BACKEND_URL}/categories`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((response) => {
          if (!response.hasOwnProperty("error")) {
           setCategories(response)
          }
        })
        .catch((error) => {
          console.error("error: ", error);
        })
        .finally(() => {
          console.log("finally")
        })
  }, []);

  useEffect(() => {
      fetch(`${BACKEND_URL}/subcategories`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((response) => {
          if (!response.hasOwnProperty("error")) {
           setSubCategories(response.result)
          }
        })
        .catch((error) => {
          console.error("error: ", error);
        })
        .finally(() => {
          console.log("finally")
        })
  }, [JSON.parse(user)?.user.id, user]);

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setArticles({...articles, image: URL.createObjectURL(event.target.files[0] )});
    }
   }

   const onChangeDate = (eventDate) => {
    setDate(eventDate.toDate())
  }

   const onChange = (e) => {
    if(e.target.name === "kategorie"){
      setCategoryId(e.target[e.target.selectedIndex].id)
    } 
    else{
      setSubCategoryId(e.target[e.target.selectedIndex].id)
    }
  }

  const editArticles = async (e) => {
    e.preventDefault();
    let resp;
    const data = getValues();
    const body = new FormData();
    body.set("key", "f32652bb4b2bc7d525ff9f56256361f7");
    body.append("image", data.image[0]);
    if (data?.image !== "") {
      resp = await axios({
        method: "POST",
        url: "https://api.imgbb.com/1/upload",
        data: body,
      });
    }
    setTimeout(() => {
      fetch(`${BACKEND_URL}/edit-articles`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          id: params.id,
          user_id: articles?.user_id,
          date: date === "" ? articles?.date : date,
          title: data.title === "" ? articles?.title : data?.title,
          description: data.description === "" ? articles?.description : data?.description,
          long_description:data.long_description === "" ? articles.long_description : data?.long_description,
          category_id: categoryid === 0 ? articles.category_id :categoryid,
          subcategory_id: subcategoryid === 0 ? articles.subcategory_id :subcategoryid,
          image: resp ? resp.data.data.url: articles.image
        }),
      })
        .then((response) => response.json())
        .then((response) => {
          if (response.statusCode === 200) {
            cogoToast.success("Artykuł został zaaktulizowany");
            navigate("/");
          } 
        })
        .catch((error) => console.error("error: ", error));
    }, [2000]);
  };
  return (
    <Layout>
      <header className="text-center max-w-2xl mx-auto - mb-14 sm:mb-16 lg:mb-20 ">
        <Heading2>Edycja Artykuł</Heading2>
      </header>

      <div className="max-w-md mx-auto space-y-6">
        {/* FORM */}
        <form className="grid grid-cols-1 gap-6" action="#" method="post">
          <label className="block">
            Tytuł artykułu: {articles?.article_title}
            <Input
             {...register("title", {
            //   required: { value: true, message: "Pole wymagane" },
            //   maxLength: 20,
            //   minLength: 3,
            })}
              type="text"
              placeholder={articles?.article_title}
              className="mt-1"
            />
          </label>
          <label className="block">
          Krótki opis artykułu: {articles?.article_description}
            <Input
             {...register("description", {
            //   required: { value: true, message: "Pole wymagane" },
            //   maxLength: 20,
            //   minLength: 3,
            })}
              type="text"
              placeholder={articles?.description}
              className="mt-1"
            />
          </label>
          <label className="block">
          Długi opis artykułu: {articles?.long_description}
            <textarea
             {...register("long_description", {
            //   required: { value: true, message: "Pole wymagane" },
            //   maxLength: 20,
            //   minLength: 3,
            })}
              // type="text"
              style={{height:"205px", width:"445px", borderRadius:"20px"}}
              placeholder={articles?.long_description}
              className="mt-1"
            />
          </label>
          <label className="block">
            <h2>{articles?.title}</h2>
            <select name="kategorie" onChange={onChange} style={{borderRadius:"9999px"}}>
            <option>Wybierz kategorie</option>
            {categories.map((category, index) => {
              return <option id={category.id} key={category.id} value={category.name}>{category.name}</option>
            })}
            </select>
          </label>
          <label className="block">
          <h2>{articles?.name}</h2>
            <select name="podkategorie" onChange={onChange} style={{borderRadius:"9999px"}}>
            <option>Wybierz podkategorie</option>
            {subcategories.map((subcategory, index) => {
              return <option id={subcategory.id} key={subcategory.id} value={subcategory.title}>{subcategory.title}</option>
            })}
            </select>
          </label>
          <label>
            <h2>{new Date(articles?.date).toLocaleString()}</h2>
          <Datetime className="form-control" locale="pl" onChange={onChangeDate} />
          </label>
          <label className="block">
            <Input {...register("image", {
              onChange: onImageChange
            })} type="file" max-size="2000" className="mt-1" />
            <img width={100} alt="model" src={articles?.image}/>
          </label>
        </form>
        <ButtonPrimary onClick={editArticles}>Zaaktualizuj artykuł</ButtonPrimary>
      </div>
    </Layout>
  );
};

export default PageEditArticles;