import Card9 from "components/Card9/Card9"
import CardCategory1 from "components/CardCategory1/CardCategory1";
import CardCategoryHeader from "components/CardCategoryHeader/CardCategoryHeader"
import WidgetHeading1 from "components/WidgetHeading1/WidgetHeading1";
import { DEMO_CATEGORIES } from "data/taxonomies";
import { TaxonomyType } from "data/types";
import React, { FC } from "react";
import { Link } from "react-router-dom"

const WidgetCategoriesFirst = ({
  className = "bg-neutral-100",
  categories,
  posts,
}) => {
  const filtered = posts.filter((post) => post.category_id == 5)
  .sort(() => function(a, b){return a - b})
  return (
    <div
      className={`nc-WidgetCategories overflow-hidden`}
    >
      <div className="flow-root">
        <div className="flex flex-col divide-y divide-neutral-200 dark:divide-neutral-700">
        {/* <WidgetHeading1
        title="Polecane artykuły"
        viewAll={{ label: "Zobacz wszystkie", href: "/#" }}
      /> */}
          {filtered
          .slice(filtered.length - 2, filtered.length -1)
          .map((post) => (
            <div key={post.id}>
            <Link style={{color:"black", marginBottom:"0px"}} to={`/${post?.title.toLowerCase()}/${post?.name.toLowerCase()}/${post.category_id}/${post.subcategory_id}/artykuł/${post.id}`} className="line-clamp-3" title={post.article_title}>
            <h3 style={{fontSize: "28px", fontWeight:"bold", lineHeight:"34px", maxWidth:"369px"}}>{post.article_title}</h3>
            </Link>
            <div key={post.id} style={{display:"flex", flexFlow:"column", alignItems:"baseline", gap:"11px"}}>
              <div style={{display:'flex', flexFlow:"column", gap:"7px"}}>
              <span style={{color:"red",fontWeight: 700, margin:"4px 0 4px 0"}}><b>{post.title}</b></span>
              <p style={{width:"360px", textAlign:"justify"}}>{post.long_description.slice(0,140)}...</p>
              </div>
              <Card9 id={post?.id} category={post?.name} subcategory={post?.title} category_id={post?.category_id} subcategory_id={post?.subcategory_id} key={post.id} post={post} />
          </div>
          </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default WidgetCategoriesFirst;
