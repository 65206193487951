import React, { FC } from "react";
import image from "../../images/baner_respublica.jpg"

export interface NcImageProps extends Partial<HTMLImageElement> {
  containerClassName?: string;
  fill?: boolean;
}

const NcImage: FC<NcImageProps> = ({
  containerClassName = "container",
  alt = "nc-imgs",
  fill = false,
  className = "object-contain image-box w-full h-full",
  sizes = "(max-width: 600px) 480px, 800px",
  ...args
}) => {
  return (
    <div className={containerClassName}>
       {/* <h1 style={{position:"absolute", fontFamily:'poppins', top:"56%", left:"34%", zIndex:"1000", color:"white", fontSize:"67px"}}>Informacje</h1> */}
      {/* @ts-ignore */}
      <img
      src={image}
        {...args}
        alt={alt}
        // sizes={sizes}
        className={
          className +
          (fill ? "absolute inset-0 w-full h-full" : "")
        }
      />
    </div>
  );
};

export default NcImage;
