import React, { useEffect, useRef, useState } from "react";
import NcImage from "components/NcImage/NcImage";
import SingleHeader from "app/(singles)/SingleHeader";
import Layout from "../layout";
import { useBeforeUnload, useParams } from "react-router-dom"
import { BACKEND_URL } from "constants"
import SectionSliderPosts from "components/Sections/SectionSliderPosts"

const PageSingle = () => {
  const params = useParams()
  const [data,setData] = useState([])
  const [articles,setArticles] = useState([])
  const initialized = useRef(false);

  console.log(data[0]?.long_description.split("/")[0])


  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      fetch(`${BACKEND_URL}/article/${+params.id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((response) => {
          if (!response.hasOwnProperty("error")) {
           setData(response)
          }
        })
        .catch((error) => {
          console.error("error: ", error);
        })
        .finally(() => {
          console.log("finally")
        })
    }
  }, []);

  useEffect(() => {
      fetch(`${BACKEND_URL}/articles`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((response) => {
          if (!response.hasOwnProperty("error")) {
           setArticles(response)
          }
        })
        .catch((error) => {
          console.error("error: ", error);
        })
        .finally(() => {
          console.log("finally")
        })
  }, []);

  return (
    <>
     {/* <Layout data={data}> */}
      <div className={`nc-PageSingle pt-8 lg:pt-16`}>
        <header className="container rounded-xl">
          <div style={{maxWidth:"869px"}}className="mx-auto">
            <SingleHeader data={data} />
          </div>
        </header>

        {/* FEATURED IMAGE */}
        {/* <div> */}
        <div className="container single-responsive">
        {/* <p style={{maxWidth:"1371px", textAlign:'justify'}}>{data[0]?.long_description.slice(0,1196)}</p> */}
          <div style={{display:"flex", gap: '36px', flexFlow:"column", alignItems:"flex-start"}}>
           <NcImage
          style={{maxWidth:"628px", height:"360px"}}
          alt="single"
          containerClassName=""
          className="w-full"
          src={data[0]?.image}
          width={1000}
          height={900}
          sizes="(max-width: 1024px) 100vw, 1280px"
        />
         {/* <NcImage
          style={{width:"100%"}}
          alt="single"
          containerClassName=""
          className="w-full"
          src={data[0]?.image}
          width={1000}
          height={750}
          sizes="(max-width: 1024px) 100vw, 1280px"
        /> */}
          </div>
          <h4>{data[0]?.long_description.split("/")[0]}</h4>
        <p style={{maxWidth:"873px", textAlign:'justify'}}>{data[0]?.long_description.split("/")[1]}</p>
        <h4>{data[0]?.long_description.split("/")[2]}</h4>
        <h5>{data[0]?.long_description.split("/")[3]}</h5>
        <p style={{maxWidth:"873px", textAlign:'justify'}}>{data[0]?.long_description.split("/")[4]}</p>
        <h4>{data[0]?.long_description.split("/")[5]}</h4>
        <p style={{maxWidth:"873px", textAlign:'justify'}}>{data[0]?.long_description.split("/")[6]}</p>
        <p style={{maxWidth:"873px", textAlign:'justify'}}>{data[0]?.long_description.split("/")[7]}</p>
        </div>
        <div className="container">
        <SectionSliderPosts
          className="py-16"
          postCardName="card10"
          heading="Zobacz też"
          subHeading="Inne Artykuły"
          posts={articles.filter((_, i) => i < 97 && i>=91)}
        />
        </div>
        {/* </div> */}
      </div>
      </>
  );
};

export default PageSingle;
