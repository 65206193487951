import React, { useEffect, useMemo } from "react";
import HeaderLogged from "components/Header/HeaderLogged";
import Header from "components/Header/Header";
import Header2 from "components/Header/Header2";
import { useLocation } from "react-router-dom";

const SiteHeader = () => {
  let pathname = useLocation().pathname;
  let user = sessionStorage.getItem("user")


  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [pathname]);


  const headerComponent = useMemo(() => {
    let HeadComponent = HeaderLogged;

    switch (pathname) {
      case "/get-articles":
        HeadComponent = HeaderLogged;
        break;
      case "/get-all-articles":
        HeadComponent = HeaderLogged;
        break;
      case "/add-articles":
        HeadComponent = HeaderLogged;
        break;
      case "/author":
        HeadComponent = HeaderLogged;
        break;
      case "/add-account":
        HeadComponent = HeaderLogged;
        break;
      case "/login":
        HeadComponent = user?.length ? Header2: Header;
        break;
        case "/kraj":
          HeadComponent = user?.length ? HeaderLogged: Header;
          break;
        case "/świat":
          HeadComponent = user?.length ? HeaderLogged: Header;
          break;
        case "/region":
          HeadComponent = user?.length ? HeaderLogged: Header;
          break;
        case "/biznes/kraj/3/1":
          HeadComponent = user?.length ? HeaderLogged: Header;
          break;
        case "/polityka/kraj/3/2":
          HeadComponent = user?.length ? HeaderLogged: Header;
          break;
        case "/wydarzenia/kraj/3/3":
          HeadComponent = user?.length ? HeaderLogged: Header;
          break;
        case "/sport/kraj/3/4":
          HeadComponent = user?.length ? HeaderLogged: Header;
          break;
        case "/gospodarka/region/5/1":
            HeadComponent = user?.length ? HeaderLogged: Header;
            break;
        case "/polityka/region/5/2":
            HeadComponent = user?.length ? HeaderLogged: Header;
            break;
        case "/społeczeństwo/region/5/3":
            HeadComponent = user?.length ? HeaderLogged: Header;
            break;
        case "/sport/region/5/4":
            HeadComponent = user?.length ? HeaderLogged: Header;
            break;
        case "/biznes/świat/1/1":
          HeadComponent = user?.length ? HeaderLogged: Header;
          break;
        case "/polityka/świat/1/2":
          HeadComponent = user?.length ? HeaderLogged: Header;
          break;
        case "/wydarzenia/świat/1/3":
          HeadComponent = user?.length ? HeaderLogged: Header;
          break;
        case "/sport/świat/1/3":
          HeadComponent = user?.length ? HeaderLogged: Header;
          break;
        case "/":
          HeadComponent = user?.length ? HeaderLogged: Header2;
          break;
    }

    return <HeadComponent />;
  }, [pathname]);

  return <>{headerComponent}</>;
};
export default SiteHeader;
