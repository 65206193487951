import React, { FC, useEffect, useRef, useState } from "react";
import Card8 from "components/Card8/Card8";
import { SectionMagazine1Props } from "./SectionMagazine1";
import HeaderFilter from "./HeaderFilter";
import Card9 from "components/Card9/Card9";
import { BACKEND_URL } from "constants"
import Card8Special from "components/Card8Special/Card8Special"
import { Link } from "react-router-dom"

// export interface SectionMagazine4Props extends SectionMagazine1Props {}

const SectionMagazineFirstSpecial = ({
  posts,
  className = "",
  category_id,
  category,
  subcategory_id,
  heading = "Latest Articles 🎈",
}) => {

  // const postWithStatus1 = posts
  //   .find((item) => item.category_id === category_id && item.subcategory_id === subcategory_id && item.status === 1);

  //   const postWithStatus2 = posts
  //   .find((item) => item.category_id === category_id && item.subcategory_id === subcategory_id && item.status === 2);

  return (
      <div className={`nc-SectionMagazine4 ${className}`}>
        {/* <HeaderFilter heading={heading} /> */}
        <h2 style={{margin:"0px"}}
          className={`mt-3 relative block font-semibold text-neutral-50 text-lg sm:text-2xl`}
        >
          <Link style={{color:"black",marginBottom:'10px'}} 
          to={`/${posts[108]?.title.toLowerCase()}/${posts[108]?.name.toLowerCase()}/${posts[108]?.category_id}/${posts[108]?.subcategory_id}/artykuł/${posts[108]?.id}`} className="line-clamp-3" title={posts[108]?.article_title}>
            {posts[108]?.article_title}
          </Link>
        </h2>
  
        {!posts?.length && <span>Nie znaleziono artykułów!</span>}
        <div style={{display:"flex", flexFlow:"column", paddingBottom:"23px", gap:"10px"}}>
        <span style={{color:"red",fontWeight:700}}><b>{posts[108]?.title}</b></span>
        <p style={{color:"black", marginBottom:"0px"}}>{posts[108]?.long_description.slice(0,200)}...</p>
        </div>
        <div>
          <Card8Special category={category} className="sm:col-span-2" post={posts[108]} />
          {/* } */}
          {/* {posts
            .filter((_, i) => i < 5 && i >= 3)
            .map((item, index) => (
              <Card9 key={index} post={item} />
            ))} */}
          {/* {posts
            .filter((item , i) => item.category_id === category_id && item.subcategory_id === subcategory_id)
            .map((item, index) => (
              <Card8 category={category} key={item.id} className="sm:col-span-2" post={item} />
            ))} */}
        </div>
      </div>
    );
};

export default SectionMagazineFirstSpecial;
