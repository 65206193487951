import React from "react";
import logo from "../../images/logo małe.jpg"

const LogoSvgMobile = () => {
  return (
    <img
    style={{marginTop:"3px"}}
    src={logo}
      width="30"
      height="41"/>
  );
};

export default LogoSvgMobile;
