import { BACKEND_URL } from "./constants"

export const authToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcGlrZXkiOiJjMmQyZGExMS0wOWNjLTQ5OTAtYmFlNS02ZDIxZTZmNTRhZGIiLCJwZXJtaXNzaW9ucyI6WyJhbGxvd19qb2luIl0sImlhdCI6MTczMzI2Mzc3NiwiZXhwIjoxNzM1ODU1Nzc2fQ.5iP6ulOGwHXf_DwQMnVWQk0QLWFRx38rrnC9xmcZoOo";

export const createNewRoom = async () => {
  const res = await fetch(`https://api.videosdk.live/v2/rooms`, {
    method: "POST",
    headers: {
      authorization: `${authToken}`,
      "Content-Type": "application/json",
    },
  });

  const { roomId } = await res.json();
  fetch(`${BACKEND_URL}/addLive`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({
     live: `${window.location.href}?roomId=${roomId}`
    }),
  })
    .then((response) => response.json())
    .then((response) => {
      if (response.statusCode === 200) {
        console.log("res",response)
      } else if (response.statusCode === 404) {
        console.log("error")
      }
    })
  return roomId;
};

