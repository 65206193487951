import React from "react";
import { useNavigate } from 'react-router-dom';
import { useForm } from "react-hook-form";
import cogoToast from "cogo-toast";
import facebookSvg from "images/Facebook.svg";
import twitterSvg from "images/Twitter.svg";
import googleSvg from "images/Google.svg";
import Input from "components/Input/Input";
import ButtonPrimary from "components/Button/ButtonPrimary";
import { BACKEND_URL } from "../../../constants";
import NcLink from "components/NcLink/NcLink";
import Heading2 from "components/Heading/Heading2";
import Image from "components/Image";
import Layout from "../layout";

const PageLogin = () => {
  const navigate = useNavigate()
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const onLogin = (e) => {
    e.preventDefault();
    const data = getValues();
    setTimeout(() => {
      fetch(`${BACKEND_URL}/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          email: data.email,
          password: data.password,
        }),
      })
        .then((response) => response.json())
        .then((response) => {
          if (response.statusCode === 200) {
            cogoToast.success("Zalogowany");
            sessionStorage.setItem("user", JSON.stringify(response));
            navigate("/");
          } else if (response.statusCode === 400) {
            cogoToast.error("Nieprawidłowe hasło lub email");
          } else if (response.statusCode === 500) {
            cogoToast.error(
              "Nie znaleziono użytkownika, prosze spróbować jeszcze raz"
            );
          } else if (response.statusCode === 404) {
            cogoToast.error(
              "Nie znaleziono użytkownika, prosze spróbować jeszcze raz"
            );
          }
        })
        .catch((error) => console.error("error: ", error));
    }, [2000]);
  };
  return (
    <Layout>
      <header className="text-center max-w-2xl mx-auto - mb-14 sm:mb-16 lg:mb-20 ">
        <Heading2>Logowanie</Heading2>
        <span className="block text-sm mt-2 text-neutral-700 sm:text-base dark:text-neutral-200">
         Witamy
        </span>
      </header>

      <div className="max-w-md mx-auto space-y-6">
        {/* <div className="grid gap-3">
          {loginSocials.map((item, index) => (
            <a
              key={index}
              href={item.href}
              className="flex w-full rounded-lg bg-primary-50 dark:bg-neutral-800 px-4 py-3 transform transition-transform sm:px-6 hover:translate-y-[-2px]"
            >
              <Image
                className="flex-shrink-0"
                src={item.icon}
                alt={item.name}
              />
              <h3 className="flex-grow text-center text-sm font-medium text-neutral-700 dark:text-neutral-300 sm:text-sm">
                {item.name}
              </h3>
            </a>
          ))}
        </div> */}
        {/* <div className="relative text-center">
          <span className="relative z-10 inline-block px-4 font-medium text-sm bg-white dark:text-neutral-400 dark:bg-neutral-900">
            Lub
          </span>
          <div className="absolute left-0 w-full top-1/2 transform -translate-y-1/2 border border-neutral-100 dark:border-neutral-800"></div>
        </div> */}
        {/* FORM */}
        <form className="grid grid-cols-1 gap-6" action="#" method="post">
          <label className="block">
            <span className="text-neutral-800 dark:text-neutral-200">
              Adres E-mail
            </span>
            <Input
             {...register("email", {
              required: { value: true, message: "Pole wymagane" },
              maxLength: 20,
              minLength: 3,
            })}
              type="email"
              placeholder="example@example.com"
              className="mt-1"
            />
          </label>
          <label className="block">
            <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
              Hasło
              {/* <NcLink href="/forgot-pass" className="text-sm underline">
                Zapomniałeś hasła?
              </NcLink> */}
            </span>
            <Input {...register("password", {
              required: { value: true, message: "Pole wymagane" },
              maxLength: 20,
              minLength: 3,
            })} type="password" className="mt-1" />
          </label>
        </form>
        <ButtonPrimary onClick={onLogin}>Zaloguj się</ButtonPrimary>

        {/* ==== */}
        {/* <span className="block text-center text-neutral-700 dark:text-neutral-300">
          Nowy użytkownik? {` `}
          <NcLink href="/signup">Stwórz konto</NcLink>
        </span> */}
      </div>
    </Layout>
  );
};

export default PageLogin;
