import PageContact from "app/(others)/contact/page"
import PageContactAbout from "app/(others)/contact_about/page"
import HeaderFilter from "components/Sections/HeaderFilter"


const PageAbout = () => {
  return (
    <div className={`nc-PageAbout relative container`}>
    <HeaderFilter heading="O nas"/>
    <p>Respublica.com.pl to jeden z największych portali internetowych w regionie, dostarczający aktualne i rzetelnie przygotowane informacje ale także zapewniający rozrywkę oraz przestrzeń do wymiany opinii. Portal ma ambicję, aby stać się źródłem informacji dla mieszkańców naszego regionu, ale także zmieniać otaczającą nas wszystkich rzeczywistość na lepszą poprzez skupianie się na ważnych kwestiach społecznych z uwzględnieniem interesów każdego mieszkańca naszego regionu.</p>
      <div className="container py-16 lg:py-28 space-y-16 lg:space-y-28">

        <PageContactAbout/>
      </div>
    </div>
  );
};

export default PageAbout;
