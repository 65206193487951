import React, { CSSProperties, FC } from "react";
import { Link } from "react-router-dom"

interface Props extends Partial<Omit<HTMLImageElement, "style">> {
  fill?: boolean;
  style?: CSSProperties;
  onLoadingComplete?: () => void;
}

const Image: FC<Props> = ({
  fill = false,
  className,
  src,
  alt = "",
  style,
  onLoadingComplete,
  ...args
}) => {
  return (
    //   @ts-ignore
    <img
     
      src={src}
      className={
        className + (fill ? " object-cover inset-0 w-full h-full" : "")
      }
      alt={alt}
      style={style}
      onLoad={onLoadingComplete}
      {...args}
    />
  );
};

export default Image;
