import React, { FC } from "react";
import MainNav1 from "./MainNav1"
import MainNav2Logged from "./MainNav2Logged";

export interface HeaderLoggedProps {}

const HeaderLogged: FC<HeaderLoggedProps> = () => {
  const user = sessionStorage.getItem("user")
  return (
    <div className="nc-HeaderLogged top-0 w-full z-40">
      {user?.length ? <MainNav2Logged />: <MainNav1/>}
    </div>
  );
};

export default HeaderLogged;
