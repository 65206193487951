import React, { FC } from "react";
import Card8 from "components/Card8/Card8";
import { SectionMagazine1Props } from "./SectionMagazine1";
import HeaderFilter from "./HeaderFilter";
import Card9 from "components/Card9/Card9";
import { Link } from "react-router-dom"

// export interface SectionMagazine4Props extends SectionMagazine1Props {}

const SectionMagazineSecond5 = ({
  posts,
  className = "",
  category_id,
  category,
  subcategory_id,
  heading = "Latest Articles 🎈",
}) => {
  const filteredPosts = posts
  .filter((item) => item.category_id === category_id);
  return (
    <div className={`nc-SectionMagazine4 ${className}`}>
      <HeaderFilter heading={heading} />
      {/* <hr></hr> */}

      {!posts?.length && <span>Nothing we found!</span>}
      <div
      //  className=" grid xl:grid-cols-3 gap-6 md:gap-8"
      className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-6 md:gap-8"
      >
      {/* {posts[30] && <Card8 category={category} className="sm:col-span-2" post={posts[30]} />} */}
        {subcategory_id !== null && filteredPosts.slice(2, 8)
          // .filter((item, i) => {  console.log('itemys: ', item); return i < 9 && i >= 1 })
          .map((item, index) => (
            <div key={item.id} style={{display:"grid", alignContent:"space-between"}}>
            <div style={{display:'flex', flexFlow:'column', gap:"10px"}}>
            <Link to={`/${item?.title.toLowerCase()}/${item?.name === "Świat" ? "swiat" : item?.name.toLowerCase()}/${item?.category_id}/${item?.subcategory_id}/artykuł/${item?.id}`} style={{color:"black"}}><h3 style={{fontSize:'24px'}}><b>{item.article_title}</b></h3></Link>
            <span style={{color:"red",fontWeight:700}}>{item?.title}</span>
            <p>{item.long_description.slice(0,180)}...</p>
            </div>
            <Card9 key={index} post={item} imgClassName="max-height-220" />
            </div>
          ))}
          {/* :posts
          .filter((_, i) => i < 9 && i >= 1)
          .map((item, index) => (
            <Card9 key={index} post={item} />
          ))} */}
        {/* {category_id === 0 && subcategory_id === 0  && posts[5] && <Card8 className="sm:col-span-2" post={posts[5]} />} */}
      </div>
    </div>
  );
};

export default SectionMagazineSecond5;
