import React, { FC } from "react";

export interface NcImageAdsProps extends Partial<HTMLImageElement> {
  containerClassName?: string;
  fill?: boolean;
}

const NcImageAds: FC<NcImageAdsProps> = ({
  containerClassName = "container",
  alt = "nc-imgs",
  fill = false,
  className = "object-fit-contain w-full responsive-ads ",
  sizes = "(max-width: 600px) 480px, 700px",
  ...args
}) => {
  return (
    <div className={containerClassName}>
       {/* <h1 style={{position:"absolute", fontFamily:'poppins', top:"56%", left:"34%", zIndex:"1000", color:"white", fontSize:"67px"}}>Informacje</h1> */}
      {/* @ts-ignore */}
      <img
      width={150}
        {...args}
        alt={alt}
        sizes={sizes}
        // className={
        //   className +
        //   (fill ? "absolute inset-0 w-full h-full" : "")
        // }
      />
    </div>
  );
};

export default NcImageAds;
