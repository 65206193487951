import { Link } from "react-router-dom";

const NotFound = () => {
    return (
        <div className="notfound">
            <h1>Przykro mi ale nie znaleziono takiej strony</h1>
            <p>Powrót do strony głównej</p>
            <Link to='/'>Strona główna</Link>
        </div>
    )
}
export default NotFound