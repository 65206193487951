import React, { FC } from "react";
import MainNav1 from "./MainNav1"
import MainNav2 from "./MainNav2";
import MainNav2Logged from "./MainNav2Logged"

export interface HeaderProps {}

const Header: FC<HeaderProps> = () => {
  const user = sessionStorage.getItem("user")
  return (
    <div className="nc-Header top-0 w-full z-40">
    {user?.length ? <MainNav2Logged/>: <MainNav1/>}
    </div>
  );
};

export default Header;
