import PageContact from "app/(others)/contact/page"
import HeaderFilter from "components/Sections/HeaderFilter"


const PageRegulamin = () => {
  return (
    <div style={{paddingBottom:"40px"}} className={`nc-PageAbout relative container`}>
    <HeaderFilter heading="Regulamin"/>
    {/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p> */}
      <div>
       <h3>Rozdział I  <br/> §1. Postanowienia ogólne</h3>
          <p>
          1. Niniejszy Regulamin określa:
1) zasady i warunki świadczenia przez Usługodawcę Usług drogą elektroniczną, a w
szczególności:<br/>
a. Zasady funkcjonowania Portalu Internetowego Respublica.com.pl i warunki korzystania
z niej przez Użytkownika, <br/>
b. Zasady i warunki świadczenia przez Usługodawcę drogą elektroniczną innych Usług,
takich jak newsletter i formularz kontaktowy, <br/>
2) zasady przetwarzania danych osobowych, <br/>
3) wymagania techniczne niezbędne do korzystania z Usług świadczonych przez Usługodawcę
drogą elektroniczną. <br/>
2. Wszelkie Usługi świadczone drogą elektroniczną przez Usługodawcę podlegają warunkom
określonym w niniejszym Regulaminie, za wyjątkiem tych, dla których obowiązują odrębne
warunki określone w innych regulaminach, informacjach, cennikach lub innych dokumentach,
dostępnych na stronie internetowej, o której mowa w ust. 3 poniżej. <br/>
3. Niniejszy Regulamin dostępny jest na stronie internetowej www.respublica.com.pl w formacie
pdf, umożliwiającym Użytkownikowi jego odtworzenie bezpośrednio na stronie internetowej lub
pobranie go przez Użytkownika i zapisanie na zewnętrznym nośniku danych a także
wydrukowanie. <br/>
4. Korzystając z Portalu Internetowego Respublica.com.pl Użytkownik związany jest
postanowieniami niniejszego Regulaminu i zobowiązuje się do ich przestrzegania.<br/>
5. Usługodawcą i właścicielem Portalu Internetowego Respublica.com.pl jest spółka WORLD
DEVELOPMENTS Sp. z o.o. z siedzibą w Nysie (48-303), ul. Podolska 22, wpisana do
Rejestru Przedsiębiorców przez Sąd Rejonowy w Opolu, VIII Wydział Gospodarczy Krajowego
Rejestru Sądowego pod numerem KRS: 0000572761, o kapitale zakładowym w wysokości
205.000 zł, posiadająca numer NIP: 7532438114, adres email:kontakt@respublica.com.pl, numer telefonu
do kontaktu………………… <br/>
6. W kwestiach nieuregulowanych w niniejszym Regulaminie odpowiednie zastosowanie mają
obowiązujące przepisy prawa polskiego, a wszelkie spory dotyczące świadczonych przez
Usługodawcę Usług rozstrzygane będą przez właściwe sądy polskie.
          </p>
          <br/>
          <h3>§2. Definicje</h3>
          <p>Użyte w niniejszym Regulaminie określenia mają następujące znaczenie
          </p>
          <ol>
            <li>Regulamin - niniejszy regulamin, stanowiący regulamin, o którym mowa w ustawie o
świadczeniu usług drogą elektroniczną z dnia 18 lipca 2002r. (tj. z dnia 10 października 2024 r.
(Dz.U. z 2024 r. poz. 1513).</li>
<li>Usługodawca – spółka wskazana w §1 ust. 5 Regulaminu.</li>
<li>Partnerzy – osoby i podmioty współpracujące z Usługodawcą na podstawie zawartych z nim
umów, od których Usługodawca nabywa Materiały lub świadczone przez nie usługi.</li>
<li>Portal Internetowy Respublica.com.pl / Portal Internetowy – zorganizowana platforma
internetowa, składająca się ze strony internetowej www.respublica.com.pl, lub innej strony
należącej do Usługodawcy, na której publikowane są Materiały, a także z innych narzędzi
internetowych, będąca własnością Usługodawcy.</li>
<li>Materiały - treści cyfrowe w postaci artykułów prasowych, opracowań, zdjęć, filmów, grafik,
wykresów, reklam i innych treści o charakterze informacyjnym, opiniodawczym,
marketingowym, mające charakter utworów w rozumieniu ustawy o prawach autorskich i
prawach pokrewnych z dnia z dnia 4 lutego 1994 r. i objęte ochroną praw autorskich z nimi
związanych.</li>
<li>Newsletter - wiadomość przesyłana w określonych odstępach czasu za pośrednictwem e-mail
osobom, które wyraziły dobrowolnie chęć jej otrzymywania i podały swój adres mailowy,
zawierająca Materiały, które mogą być dobierane</li>
<li>Formularz kontaktowy - umieszczona w Portalu Internetowym funkcjonalność przeznaczona
dla Użytkowników, umożliwiająca wysłanie wiadomości do Usługodawcy z pominięciem poczty
elektronicznej.</li>
<li>Usługi – usługi cyfrowe świadczone drogą elektroniczną przez Usługodawcę, na podstawie
Regulaminu, udostępniane Użytkownikom za pośrednictwem Portalu Internetowego, polegające
na:
a) dostępie do publikowanych w Portalu Internetowym Materiałów tworzonych przez
Usługodawcę lub Partnerów,
b) usługi Newslettera,
c) usługi formularza kontaktowego służącego do przesyłania wiadomości Usługodawcy
bezpośrednio z Portalu Internetowego.</li>
<li>Użytkownik – osoba korzystająca z Usług Usługodawcy.</li>
<li>Pliki cookies – to pliki zawierające krótkie informacje tekstowe zapisywane przez serwer na
urządzeniu Użytkownika, które następnie wykorzystywane są przy ponownym połączeniu się
Użytkownika z Portalem Internetowym. Pliki cookies służą do zapamiętywania preferencji
Użytkowników i wybranych ustawień, a także zbierają anonimowo dane służące do celów
reklamowych i statystycznych.</li>
<li>Ustawa o świadczeniu usług drogą elektroniczną – ustawa o świadczeniu usług drogą
elektroniczną z dnia 18 lipca 2002 r.</li>
<li>RODO - Rozporządzenie Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia
2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w
sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (ogólne
rozporządzenie o ochronie danych) z dnia 27 kwietnia 2016 r.</li>
</ol>
<br/>
<h3>Rozdział II.
USŁUGI</h3>
<h4>§3. Portal Internetowy Respublica.com.pl</h4>
<ol>
            <li>Usługodawca umożliwia Użytkownikom nieodpłatny dostęp do Portalu Internetowego
Respublica.com.pl, który umożliwia przeglądanie i odczytywanie treści publikowanych przez
Usługodawcę w postaci tekstów, zdjęć, wykresów, materiałów wideo itd. o charakterze
prasowym.</li>
<li>Dostęp i korzystanie z Portalu Internetowego wymaga posiadania przez Użytkownika
urządzenia (komputera, smartfonu lub in.) posiadającego aktywny dostęp do Internetu, a także
posiadającego zainstalowaną przeglądarkę internetową, wspieraną przez producentów systemów
operacyjnych. Zalecana przeglądarka internetowa to aktualna wersja jednej z następujących

przeglądarek: Chrome, Firefox, Edge, Safari lub Opera. Przeglądarka internetowa musi
akceptować pliki cookies i obsługiwać JavaScript.</li>
<li>W przypadku umożliwienia przez Usługodawcę możliwości korzystania z Portalu Internetowego
za pośrednictwem aplikacji mobilnej, minimalne wymagania sprzętowe i programowe wskazane
zostaną w serwisie umożliwiającym pobranie i zainstalowanie aplikacji mobilnej i mogą się one
różnić w zależności od wersji aplikacji dla różnych systemów: Android, IOS lub innych.</li>
<li>Portal Internetowy może zawierać treści o charakterze reklamowym. Takie treści są wyraźnie
wyodrębnione i oznaczone jako informacje handlowe.</li>
<li>Usługodawca publikuje w Portalu Internetowym Materiały własne lub tworzone przez
Partnerów.</li>
<li>W przypadku publikacji Materiałów tworzonych przez Partnerów, takich jak np. Polska Agencja
Prasowa, Usługodawca nie jest ich twórcą, nie dokonuje modyfikacji ich treści i nie weryfikuje u
źródeł prawdziwości i rzetelności przedstawionych w takich Materiałach treści, a jedynie
zamieszcza je w Portalu, umożliwiając Użytkownikom dostęp do nich, nie ponosi zatem
odpowiedzialności za ich treść.</li>
<li>Usługodawca nie ponosi odpowiedzialności za aktualność i dokładność treści przedstawianych
w publikowanych Materiałach.</li>
<li>Usługodawca nie ponosi odpowiedzialności za sposób wykorzystania Materiałów
publikowanych w Portalu Internetowym przez Użytkowników. Ich ewentualne wykorzystanie w
jakimkolwiek celu następuje na wyłączne ryzyko Użytkownika.</li>
<li>Portal Internetowy ma charakter informacyjny. Wszelkie publikowane w nim osobiste opinie,
poglądy, interpretacje i inne formy wyrażania zdania, stanowią wyraz indywidualnych przekonań
i wiedzy osób je wyrażających i nie powinny być traktowane jako wiążące zalecenia przez
Użytkowników.</li>
<li>Portal Internetowy, jak również zawarte w nim Materiały, szata graficzna, znaki, oznaczenia, oraz
Newsletter stanowią przedmiot praw autorskich i podlegają ochronie przewidzianej przez
przepisy ustawy o prawie autorskim i prawach pokrewnych z dnia z dnia 4 lutego 1994 r. oraz
ustawy Prawo własności przemysłowej z dnia 30 czerwca 2000 r.</li>
<li>Użytkownicy uprawnieni są do korzystania z Materiałów publikowanych w Portalu
Informacyjnym wyłącznie w zakresie dozwolonego użytku. Inna forma wykorzystania
Materiałów publikowanych przez Usługodawcę wymaga jego zgody, a także zgody wszystkich
podmiotów którym przysługują prawa do takich Materiałów.</li>
<li>Korzystanie przez Użytkownika z Materiałów publikowanych w Portalu Informacyjnym nie
oznacza nabycia przez Użytkownika jakichkolwiek praw autorskich do tych Materiałów.</li>
          </ol>
          <br/>
          <h4>§4. Newsletter</h4>
<ol>
            <li>Usługodawca świadczy na rzecz Użytkowników usługę Newslettera, w postaci korespondencji
mailowej zawierającej Materiały, wysyłanej osobom, które wyraziły wolę jego otrzymywania
podając swój adres e-mail.</li>
<li>Newsletter może zawierać Materiały prasowe, w tym także dobierane pod względem preferencji
Użytkownika odnośnie ich tematyki, w przypadku wprowadzenia przez Usługodawcę takiej
funkcjonalności, materiały informacyjne na temat działań i usług Usługodawcy, a także
materiały reklamowe.</li>
<li>Usługa Newslettera jest bezpłatna.</li>
<li>W celu zapisania się do usługi Newslettera, w Portalu Internetowym, w zakładce Newsletter,
należy wpisać co najmniej adres mailowy, na który Newsletter ma być wysyłany, zaakceptować
niniejszy Regulamin (lub odrębny regulamin Newslettera) poprzez zaznaczenie odpowiedniego
okienka, a następnie kliknąć przycisk „Zapisz się” lub inny o podobnej treści. Usługa
Newslettera zostaje zamówiona po potwierdzeniu przez Użytkownika adresu e-mail kliknięciem
w link przesłanym na wskazany adres email. Link aktywacyjny jest aktywny przez 14 dni, a
następnie, w przypadku niepotwierdzenia adresu e-mail przez Użytkownika, jego dane są
automatycznie usuwane.</li>
<li>Podanie swojego adresu e-mail oraz wyrażenie chęci otrzymywania Newslettera jest
równoznaczne z wyrażeniem zgody na przetwarzanie tych danych Użytkownika przez
Usługodawcę w celu wysyłania wiadomości e-mail z Newsletterem i otrzymywania treści
umieszczonych w Newsletterze.</li>
<li>Niedozwolone jest podawanie adresów e-mail innych osób, niż adres należący do Użytkownika
wypełniającego zgodę na otrzymywanie Newslettera.</li>
<li>Minimalne wymagania techniczne dla obsługi usługi Newslettera to:
a) posiadanie aktywnego adresu e-mail,
b) posiadanie urządzenia (komputera, tabletu, smartfonu) wyposażonego w oprogramowanie
służące do obsługi poczty elektronicznej e-mail.</li>
<li>Newsletter przeznaczony jest dla osób powyżej……..roku życia.</li>
<li>W związku z faktem, iż niektóre serwery mogą traktować Newsletter jako spam, Usługodawca
zaleca, aby w razie nieotrzymania zamówionego Newslettera, sprawdzać katalog spam w swojej
skrzynce email i dodać adres mailowy korespondencji zawierającej Newsletter do zaufanych
nadawców.</li>
<li>Wszelkie reklamacje dotyczące zamówionej usługi Newslettera należy wysyłać na adres
mailowy:newslettera@respublica.com.pl lub pisemnie na adres:ul. Podolska 22, Nysa (48-303).
Reklamacja zostanie rozpatrzona w terminie 14 dni od daty jej otrzymania, a odpowiedź w tej
sprawie wysłana na adres e-mail, z którego reklamacja została wysłana lub pisemnie na adres
korespondencyjny wskazany przez Użytkownika.</li>
<li>Użytkownik ma prawo do rezygnacji z zamówionej usługi Newslettera w każdym czasie.
Rezygnacja następuje poprzez kliknięcie linku do rezygnacji z usługi, umieszczonego w każdej
wiadomości email zawierającej Newsletter, albo poprzez wysyłanie wiadomości email do
Usługodawcy na wskazany adres:ul. Podolska 22, Nysa (48-303).
Przykładowa treść odstąpienia od usługi Newslettera:

Miejscowość, data…..

Do Portalu Internetowego Respublica.com.pl
Niniejszym odstępuję od zamówionej usługi Newslettera, przesyłanego na mój adres
email:newslettera@respublica.com.pl</li>
<li>Usługodawca ma prawo do rezygnacji z usługi przesyłania Newslettera w każdym czasie. W
takim wypadku Usługodawca uprawniony jest do odstąpienia od wszystkich umów zawartych z
Użytkownikami o dostarczanie Newlettera, poprzez powiadomienie ich wiadomością zbiorczą o
takim odstąpieniu, z 2 -tygodniowym okresem wypowiedzenia.</li>
          </ol>
          <br/>
          <h4>§5. Formularz kontaktowy</h4>
          <ol>
            <li>Usługodawca umożliwia Użytkownikom wysłanie do siebie wiadomości bezpośrednio z
poziomu Portalu Internetowego, z pominięciem poczty elektronicznej, poprzez zamieszczony na
stronie internetowej Formularz kontaktowy.</li>
<li>W celu skorzystania z Formularza kontaktowego Użytkownik wypełnia Formularz kontaktowy,
podając wskazane tam dane i wpisując treść wiadomości.</li>
<li>Podanie danych osobowych oraz adresu email i wyrażenie zgody na ich wykorzystanie w celu
obsługi Formularza kontaktowego jest dobrowolne, jednak Usługodawca zastrzega, że bez
podania tych danych nie będzie miał możliwości świadczenia usługi przez Formularz
kontaktowy oraz udzielenia ewentualnej odpowiedzi.</li>
          </ol>
          <br/>
          <h3>ROZDZIAŁ III.</h3>
          <h4>§7. Reklamacje</h4>
          <ol>
            <li>Wszelkie reklamacje w sprawie funkcjonowania Portalu Internetowego a także dotyczące usług
Newslettera czy Formularza kontaktowego należy kierować na adres
mailowy:reklamacje@respublica.com.pl  lub pisemnie na adres:ul. Podolska 22, Nysa (48-303), wskazując
zwrotne dane korespondencyjne: imię, nazwisko i adres.</li>
<li>Reklamacja zostanie rozpatrzona niezwłocznie, nie później jednak niż w terminie 14 dni od daty
jej otrzymania, a odpowiedź w tej sprawie wysłana na adres e-mail, z którego reklamacja została
wysłana lub pisemnie na adres korespondencyjny wskazany przez Użytkownika.</li>
<li>Podanie adresu e-mail lub danych korespondencyjnych w składanej reklamacji jest dobrowolne,
jednak brak wskazania zwrotnego adresu e-mail lub adresu korespondencyjnego oraz imienia i
nazwiska w przypadku pisemnej reklamacji uniemożliwi Usługodawcy prawidłowe rozpatrzenie
reklamacji i przesłanie odpowiedzi.</li>
          </ol>
          <br/>
          <h3>ROZDZIAŁ IV.</h3>
          <h4>§7. Przetwarzanie danych osobowych</h4>
          <ol>
            <li>Wszelkie dane osobowe Użytkowników przetwarzane są zgodnie z obowiązującymi przepisami,
w szczególności zgodnie z Rozporządzeniem Parlamentu Europejskiego i Rady (UE) 2016/679
z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem
danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy
95/46/WE (ogólne rozporządzenie o ochronie danych) z dnia 27 kwietnia 2016 r.</li>
<li>Dane osobowe Użytkowników przetwarzane są wyłącznie w niezbędnym zakresie, w celu
wykonania danej Usługi. W innym celu dane te mogą być przetwarzane wyłącznie jeśli istnieje w
tym zakresie podstawa prawna.</li>
<li>Administratorem danych osobowych Użytkowników jest spółka WORLD DEVELOPMENTS
Sp. z o.o. z siedzibą w Nysie (48-303), ul. Podolska 22.</li>
<li>Szczegółowe informacje dotyczące administratora danych osobowych oraz zasad przetwarzania
danych osobowych Użytkowników a także pouczenia o prawach osób, których dane są
przetwarzane, znajdują się w „Polityce Prywatności” dostępnej pod
adresem:https://respublica.com.pl/rodo</li>
          </ol>
          <br/>
          <h3>ROZDZIAŁ V.</h3>
          <h4>§7. Pliki Cookies</h4>
          <ol>
            <li>Portal Internetowy wykorzystuje Pliki cookies w celu maksymalnego ułatwienia Użytkownikom
korzystania z Portalu Internetowego, poprzez zapamiętywanie ustawień na stronie internetowej i
preferencji Użytkownika, w celu personalizacji treści udostępnianych Materiałów, a także
gromadzi anonimowo dane wykorzystywane do celów reklamowych przez Usługodawcę.</li>
<li>Szczegółowe informacje na temat polityki prywatności, w tym dotyczące wykorzystywania Plików
cookies znajdują się w „Polityce Prywatności” dostępnej pod adresem:https://respublica.com.pl/rodo
</li>
          </ol>
          <br/>
          <h3>ROZDZIAŁ VI.</h3>
          <h4>§8 Postanowienia końcowe</h4>
          <ol>
            <li>Usługodawca świadczy Usługi na podstawie niniejszego Regulaminu.</li>
            <li>Usługodawca ma prawo do zmiany postanowień niniejszego Regulaminu w każdym czasie. O
zmianie Regulaminu poinformuje on z wyprzedzeniem co najmniej 7-dniowym przed wejściem
w życie nowego Regulaminu.</li>
<li>Regulamin obowiązuje od chwili jego opublikowania w Portalu Informacyjnym.</li>
<li>Usługodawca zapewnia, że wedle jego wiedzy brak jest szczególnych zagrożeń związanych z
korzystaniem z Usług świadczonych za pośrednictwem Portalu Informacyjnego.</li>
<li>Usługodawca nie zobowiązywał się do przestrzegania kodeksu dobrych praktyk, o którym mowa
w art. 2 pkt 5 ustawy z 23 sierpnia 2007 r. o przeciwdziałaniu nieuczciwym praktykom
rynkowym.</li>
          </ol>
      </div>
    </div>
  );
};

export default PageRegulamin;
