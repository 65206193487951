import React, { useEffect, useRef, useState } from "react";
import SectionMagazineCategories from "components/Sections/SectionMagazineCategories"
import { useParams } from "react-router-dom"
import { Slide } from 'react-slideshow-image';
import horoskop from "../../../images/horoskop.jpg"
import 'react-slideshow-image/dist/styles.css'
import HeaderFilter from "components/Sections/HeaderFilter"

const PageHoroskop = () => {
  const [data,setData] = useState([])
  const initialized = useRef(false);


  const spanStyle = {
    padding: '20px',
    background: "none",
    color: 'white',
    fontSize:"50px"
  }
  
  const divStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundSize: 'cover',
    height: '250px'
  }

  const slideImages = [
    // {
    //   url: 'https://images.unsplash.com/photo-1509721434272-b79147e0e708?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1500&q=80',
    //   caption: "Horoskop"
    // },
    // {
    //   url:"https://i.ibb.co/LttdKXB/pexels-sharefaith-491036-1202723.jpg",
    //   caption: "Horoskop"
    // },
    {
      url: horoskop,
      caption: "Horoskop"
    },
  ]; 


//   useEffect(() => {
//     if (!initialized.current) {
//       initialized.current = true;
//       fetch(`${BACKEND_URL}/subcategories`, {
//         method: "GET",
//         headers: {
//           "Content-Type": "application/json",
//         },
//       })
//         .then((response) => response.json())
//         .then((response) => {
//           if (!response.hasOwnProperty("error")) {
//            setData(response.result)
//           }
//         })
//         .catch((error) => {
//           console.error("error: ", error);
//         })
//         .finally(() => {
//           console.log("finally")
//         })
//     }
//   }, []);

  return (
    <div className={`nc-PageArchiveAudio `}>
      {/* HEADER */}
      <div className="w-full px-2 xl:max-w-screen-2xl mx-auto">
        <div className="container" style={{marginTop:"37px", textAlign:"left"}}>
          <HeaderFilter heading="Horoskop"/>
        {/* <Slide>
         {slideImages.map((slideImage, index)=> (
            <div className="container" key={index}>
              <div style={{ ...divStyle, 'backgroundImage': `url(${slideImage.url})` }}>
              </div>
            </div>
          ))} 
        </Slide> */}
          {/* <div className="absolute inset-0 text-white flex flex-col items-center justify-center">
            <h2 style={{fontSize:"50px"}} className="inline-block align-middle ml-3">
              Horoskop
            </h2>
          </div> */}
        </div>
      </div>
      {/* ====================== END HEADER ====================== */}

      <div className="container lg:pb-28 space-y-16 lg:space-y-28">
        <div>
          {/* <div className="flex flex-col sm:items-center sm:justify-between sm:flex-row">
            <div className="block my-4 border-b w-full border-neutral-300 dark:border-neutral-500 sm:hidden"></div>
          </div> */}
              <h2>Grudzień 2024</h2>
            <div className="horoskop-container">
            <div className="horoskop-content">
                <h4 style={{maxWidth:"400px"}} className="inline-block align-middle font-semibold ">
                Baran 21 marca do 19 kwietnia
                </h4>
                <p>
                W pracy odczujesz przypływ energii, która pozwoli Ci skutecznie zrealizować najtrudniejsze zadania. Twoja ambicja i determinacja zostaną zauważone, co może zaowocować nowymi możliwościami. Zwróć uwagę na współpracę z innymi – nie musisz wszystkiego robić samodzielnie. Twoje zdrowie wymaga większej troski. Zimne poranki mogą osłabić Twoją odporność, dlatego warto włączyć do diety więcej witamin i ciepłych napojów. Może to dobry moment, by spróbować rozgrzewających zup i herbat z imbirem? Unikaj też przemęczania się – równowaga to podstawa. W relacjach międzyludzkich czeka Cię miłe zaskoczenie. Ktoś z przeszłości może nawiązać kontakt, co będzie świetną okazją do odświeżenia dawnych znajomości. Bliscy docenią Twoją energię i otwartość, więc znajdź czas na wspólne spotkania – to dobry sposób na wejście w grudniowy nastrój.
            </p>
            </div>
            <div className="horoskop-content">
                <h4 style={{maxWidth:"400px"}} className="inline-block align-middle font-semibold ">
                Byk 20 kwietnia do 22 maja
                </h4>
                <p>
                W pracy tydzień może być nieco chaotyczny, ale jeśli zachowasz cierpliwość i skupienie, osiągniesz zamierzone cele. Postaraj się nie ulegać presji i trzymaj się swojego tempa. Pamiętaj, że grudzień, to dobry moment, by zacząć planować długofalowe projekty. Twoje zdrowie będzie stabilne, o ile zadbasz o wystarczającą ilość snu i relaks. Długie jesienne wieczory sprzyjają spokojnym chwilom z książką czy kubkiem ciepłej herbaty. Zastanów się, czy nie warto spróbować lekkich ćwiczeń w domu – to poprawi Twoje samopoczucie. Horoskop tygodniowy zdradza, że w relacjach możesz poczuć lekkie napięcie, szczególnie z najbliższymi. Postaraj się unikać konfliktów i zamiast tego skupić na budowaniu porozumienia. Czas spędzony z rodziną pomoże Ci poczuć się bardziej związanym z bliskimi i przygotować na świąteczny klimat grudnia.
            </p>
            </div>    
            <div className="horoskop-content">
                <h4 style={{maxWidth:"400px"}} className="inline-block align-middle font-semibold ">
                Bliźnięta 23 maja do 21 czerwca
                </h4>
                <p>
                W pracy możesz poczuć się przytłoczony obowiązkami. Postaraj się nie brać na siebie zbyt wielu zadań – kluczowe będzie zachowanie równowagi. Współpracownicy chętnie Cię wesprą, jeśli poprosisz o pomoc. To także dobry moment, by zastanowić się nad swoimi celami na grudzień i kolejny rok. Zdrowie może być nieco osłabione – pamiętaj o regularnym jedzeniu i odpowiedniej ilości snu. Jesienna aura sprzyja przeziębieniom, więc ciepły szalik i witamina C to podstawa. Jeśli czujesz spadek energii, spróbuj odprężyć się przy ulubionej muzyce lub zapalić aromatyczne kadzidełko. W relacjach nadchodzi dobry czas na szczere rozmowy. Być może ktoś z bliskich czuje się zaniedbany? Grudzień to idealny czas, by naprawić napięte relacje.
            </p>
            </div>    
            <div className="horoskop-content">
                <h4 style={{maxWidth:"400px"}} className="inline-block align-middle font-semibold ">
                Rak 22 czerwca do 22 lipca
                </h4>
                <p>
                Ten tydzień w pracy przyniesie Ci nowe możliwości. Zwróć uwagę na pojawiające się okazje – mogą one znacząco wpłynąć na Twoją zawodową przyszłość. Nie bój się podejmować decyzji, które wydają się ryzykowne. Twoja intuicja podpowie Ci, co zrobić. Twoje zdrowie będzie dobre, o ile zadbasz o odpowiednią regenerację. Grudzień to idealny czas, by wprowadzić zdrowe nawyki – może krótkie spacery lub nowe przepisy kulinarne? Horoskop przestrzega przed przeciążeniem – nadmiar obowiązków może odbić się na Twoim samopoczuciu. Relacje międzyludzkie będą wymagały od Ciebie większej uwagi. Bliscy mogą potrzebować Twojego wsparcia, szczególnie emocjonalnego. Pamiętaj, że Twoja empatia i ciepło są bezcenne – znajdź czas, by okazać swoją troskę, zanim grudniowe przygotowania pochłoną Was wszystkich.
            </p>
            </div>    
            <div className="horoskop-content">
                <h4 style={{maxWidth:"400px"}} className="inline-block align-middle font-semibold ">
                Lew 23 lipca do 23 sierpnia
                </h4>
                <p>
                W pracy czeka Cię okres stabilizacji. Choć nie wydarzy się nic spektakularnego, będziesz miał okazję uporządkować zaległe sprawy i przygotować się na nadchodzące wyzwania grudnia. To również dobry czas na przemyślenie swoich długofalowych planów zawodowych. Zdrowie będzie sprzyjać aktywności, ale uważaj na nadmierne obciążenie. Jesienne dni sprzyjają odpoczynkowi, więc znajdź chwilę na relaks przy filmie czy muzyce. Możesz też spróbować medytacji, która pozwoli Ci odzyskać wewnętrzny spokój i przygotować się na nadchodzącą zimę. W relacjach czeka Cię pozytywny okres. Spotkania z przyjaciółmi i rodziną przyniosą wiele radości, a Twoje pozytywne nastawienie będzie zaraźliwe. Warto pomyśleć o wspólnym planowaniu grudniowych aktywności – to zacieśni więzi i wprowadzi Was w świąteczny nastrój.
            </p>
            </div>    
            <div className="horoskop-content">
                <h4 style={{maxWidth:"400px"}} className="inline-block align-middle font-semibold ">
                Panna 24 sierpnia do 22 września
                </h4>
                <p>
                Praca w tym tygodniu będzie wymagała od Ciebie dokładności i systematyczności. Unikaj pośpiechu, bo może to prowadzić do błędów. Grudzień to dobry moment, by zastanowić się nad swoimi priorytetami i ocenić, co warto poprawić w przyszłości. Zdrowie powinno być dla Ciebie teraz priorytetem. Jesienna aura może wywoływać zmęczenie, dlatego zadbaj o odpowiednią ilość snu i zdrową dietę. Ciepłe napoje, jak herbata z miodem, pomogą Ci zregenerować siły i poprawią nastrój. W relacjach możesz poczuć pewne napięcia, szczególnie wśród najbliższych. Warto poświęcić chwilę na szczere rozmowy, które pozwolą rozwiązać ewentualne nieporozumienia. Grudzień to czas jedności – zadbaj o harmonię w relacjach już teraz. 
            </p>
            </div>    
            <div className="horoskop-content">
                <h4 style={{maxWidth:"400px"}} className="inline-block align-middle font-semibold ">
                Waga 23 września do 22 października
                </h4>
                <p>
                W pracy czeka Cię okres harmonii. Wszystko pójdzie zgodnie z planem, a Twoje zaangażowanie przyniesie oczekiwane efekty. Zbliżający się grudzień to idealny moment, by podjąć się nowych wyzwań – Twoja kreatywność i energia są na wysokim poziomie. Zdrowie będzie sprzyjać aktywności, ale pamiętaj o odpoczynku. Długie jesienne wieczory to doskonały czas na relaks przy książce lub aromatycznej kąpieli. Zadbaj też o lekką aktywność fizyczną – rozciąganie czy joga będą idealne. W relacjach czeka Cię pozytywny czas. Twoja charyzma przyciągnie ludzi, a spotkania z bliskimi będą pełne radości. To świetny moment, by zacząć planować grudniowe wydarzenia – Twoje pomysły z pewnością spotkają się z entuzjazmem.
            </p>
            </div>    
            <div className="horoskop-content">
                <h4 style={{maxWidth:"400px"}} className="inline-block align-middle font-semibold ">
                Skorpion 23 października do 21 listopada
                </h4>
                <p>
                Grudniowy horoskop mówi, że w pracy możesz napotkać pewne przeszkody. Być może nie wszystko pójdzie zgodnie z Twoim planem, ale Twoja determinacja pomoże Ci znaleźć rozwiązanie. Zaczynający się grudzień przypomina, że warto uporządkować sprawy i zamknąć zaległe projekty. Twoje zdrowie wymaga większej uwagi. Pamiętaj o ciepłym ubieraniu się i dostarczaniu organizmowi witamin. Jeśli czujesz spadek energii, spróbuj aromaterapii lub krótkiej medytacji. Jesień to czas regeneracji – zadbaj o siebie, zanim nadejdzie zima. W relacjach możesz poczuć potrzebę wycofania się na chwilę. To dobry moment na refleksję i zrozumienie swoich emocji. Bliscy zrozumieją Twoją potrzebę samotności, o ile otwarcie o niej powiesz.
            </p>
            </div>       
            <div className="horoskop-content">
                <h4 style={{maxWidth:"400px"}} className="inline-block align-middle font-semibold ">
                Strzelec 22 listopada do 21 grudnia
                </h4>
                <p>
                W pracy czeka Cię dynamiczny miesiąc. Możesz spodziewać się nowych wyzwań, które pozwolą Ci się wykazać, ale także przetestują Twoją cierpliwość. Grudzień to dobry moment, by zacząć myśleć o przyszłości i wyznaczyć sobie kolejne cele. Skup się na priorytetach, a sukces będzie w Twoim zasięgu. Twoje zdrowie może wymagać większej uwagi. Jesienna aura sprzyja infekcjom, dlatego pamiętaj o ciepłym ubiorze i odpowiedniej diecie. Regularne spacery na świeżym powietrzu mogą pomóc Ci złapać równowagę, a wieczory spędzone przy świecach lub kadzidle zrelaksują Twoje ciało i umysł. W relacjach to czas na otwarcie serca. Możesz poczuć silniejszą potrzebę bliskości z rodziną lub partnerem. Pamiętaj, że drobne gesty i szczere rozmowy potrafią zdziałać cuda. Grudniowy klimat sprzyja budowaniu ciepłych więzi, więc zacznij pielęgnować je już teraz.
            </p>
            </div>        
            <div className="horoskop-content">
                <h4 style={{maxWidth:"400px"}} className="inline-block align-middle font-semibold ">
                Koziorożec 22 grudnia do 19 stycznia
                </h4>
                <p>
                W pracy czeka Cię intensywny miesiąc. Twoje zaangażowanie zostanie docenione, ale uważaj, by nie przesadzić z perfekcjonizmem. Grudzień to świetny czas, by ocenić, co w Twojej karierze wymaga poprawy. Nie bój się delegować zadań – współpraca może przynieść lepsze efekty. Twoje zdrowie będzie stabilne, ale nie ignoruj sygnałów od swojego ciała. Zadbaj o odpowiednią regenerację i rozważ wprowadzenie do diety ciepłych posiłków, które rozgrzeją organizm. Spróbuj też znaleźć czas na drobne przyjemności, jak gorąca kąpiel czy ulubiony serial. Relacje z bliskimi będą wymagały większej uwagi. Może pojawić się napięcie, szczególnie w rodzinie, ale szczere rozmowy pomogą je rozwiązać. Warto zaplanować coś wyjątkowego – wspólne pieczenie ciasteczek czy tworzenie grudniowych dekoracji pomoże Wam zacieśnić więzi.
            </p>
            </div>        
            <div className="horoskop-content">
                <h4 style={{maxWidth:"400px"}} className="inline-block align-middle font-semibold ">
                Wodnik 20 stycznia do 18 lutego
                </h4>
                <p>
                W pracy możesz spodziewać się nowych wyzwań, które pozwolą Ci się rozwinąć. Twoja kreatywność będzie na wysokim poziomie, więc wykorzystaj ten czas na wdrażanie swoich pomysłów. Grudzień, to także dobry moment na rozmowę o swoich aspiracjach z przełożonymi – Twój głos zostanie wysłuchany. Horoskop zdradza, że Twoje zdrowie będzie dobre, ale pamiętaj o odpoczynku. Jesienna aura może wywołać spadki energii, dlatego znajdź czas na regenerację. Krótka medytacja, joga lub ciepła herbata z imbirem będą doskonałym sposobem na złapanie równowagi. W relacjach z innymi będziesz wyjątkowo charyzmatyczny. Twoja energia przyciągnie ludzi, a spotkania towarzyskie będą pełne radości. Warto jednak znaleźć też chwilę na refleksję, więc zastanów się, jak wzmocnić najważniejsze relacje w swoim życiu.
            </p>
            </div>
            <div className="horoskop-content">
                <h4 style={{maxWidth:"400px"}} className="inline-block align-middle font-semibold ">
                Ryby 19 lutego do 20 marca
                </h4>
                <p>
                W pracy możesz poczuć się nieco przytłoczony, ale nie martw się – wszystko się ułoży, jeśli zachowasz spokój. Skup się na organizacji i nie obawiaj się prosić o pomoc, gdy tego potrzebujesz. Grudzień to dobry moment, by zastanowić się nad zmianami, które mogą poprawić Twoją sytuację zawodową. Twoje zdrowie będzie wymagało większej troski. Jesienne dni mogą sprzyjać zmęczeniu, dlatego zadbaj o odpowiednią ilość snu i nawodnienie. W relacjach międzyludzkich czeka Cię czas harmonii. Bliscy będą szczególnie wdzięczni za Twoje wsparcie i empatię. Zastanów się, czy nie warto zaplanować wspólnego wieczoru jak np. wspólne dekorowanie przestrzeni w duchu zbliżającego się grudnia z pewnością wprowadzą Was w dobry nastrój. Zadbaj o swoje zdrowie, relacje i cele zawodowe, by wkroczyć w ostatni miesiąc roku z energią i spokojem. 
            </p>
            </div>                  
            </div>

          {/* <SectionMagazineCategories
            category={params.category}
            category_id={params.id}
            posts={data}
          /> */}

        </div>

        {/* <div className="relative py-16">
          <BackgroundSection />
        </div> */}
      </div>
    </div>
  );
};

export default PageHoroskop
