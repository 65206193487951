import PageContact from "app/(others)/contact/page"
import HeaderFilter from "components/Sections/HeaderFilter";
import ReactWeather, { useVisualCrossing } from 'react-open-weather';
import {
  MDBCard,
  MDBCardBody,
  MDBCol,
  MDBContainer,
  MDBIcon,
  MDBInputGroup,
  MDBRadio,
  MDBRow,
  MDBTypography,
} from "mdb-react-ui-kit";
import { useEffect, useState } from "react"
import axios from "axios"
import ButtonPrimary from "components/Button/ButtonPrimary"


const PagePogoda = () => {
  const [city, setCity] = useState("Nysa Nysa");
  const [weatherData, setWeatherData] = useState(null);
  // const { data, isLoading, errorMessage } = useVisualCrossing({
  //   key: '3GULUNGM4KAY8PFAFMERST5KR',
  //   lat: '50.47630002947185',
  //   lon: '17.336960171514384',
  //   lang: 'pl',
  //   unit: 'metric',
  // });

  useEffect(() => {
    fetchWeatherData()
  },[])
  const fetchWeatherData = async () => {
    try {
      const response = await axios.get(
        `https://api.weatherstack.com/current?access_key=71908a9ed8e5ff1e4dfc2eafea350eb4&query=${city}`
      );
      setWeatherData(response.data);
      console.log(weatherData, "weather")
    } catch (error) {
      console.log(error);
    }
  };

  // const temp = Math.round(weatherData?.currentConditions.temp/10)

  const handleSubmit = (event) => {
    event.preventDefault();
    fetchWeatherData();
  };
  return (
    <div className={`nc-PageAbout relative container`}>
    <HeaderFilter heading="Pogoda"/>
    <form style={{display:"flex", gap:"10px"}} onSubmit={handleSubmit}>
        <input
          type="text"
          style={{border:"none"}}
          placeholder="Wpisz nazwe miasta"
          value={city}
          onChange={(event) => setCity(event.target.value)}
        />
        <button className="weather-button" style={{backgroundColor:"#ab850f", border:"none",color:"white"}} type="submit">Pokaż pogodę</button>
      </form>
    <section className="vh-62" style={{ marginBottom:"20px" }}>
    {/* <ReactWeather
      isLoading={isLoading}
      errorMessage={errorMessage}
      data={data}
      lang="pl"
      locationLabel="Nysa"
      unitsLabels={{ temperature: 'C', windSpeed: 'Km/h' }}
      showForecast
    /> */}
      <MDBContainer style={{padding:"0px"}}>
        <MDBRow
          className="justify-content-start align-items-center"
          style={{ color: "#282828" }}
        >
          <MDBCol md="9" lg="7" xl="5">
            <MDBCard
              className="mb-4 gradient-custom"
              style={{ borderRadius: "25px", marginTop:"20px" }}
            >
              <MDBCardBody className="p-4">
                <div className="d-flex justify-content-between pb-2">
                  <div style={{display:'flex', gap:"20px"}}>
                    <h2 className="display-2">
                      <strong>{weatherData === null ? <span style={{fontSize:'26px'}}>Nazwa miasta</span> : `${weatherData.current.temperature}°C`}</strong>
                    </h2>
                    <h2 className="display-2">
                      <strong>{weatherData === null ? <span style={{fontSize:'26px'}}>Godzina</span> : `${new Date().getHours()}:${new Date().getMinutes()} `}</strong>
                    </h2>
                  </div>
                  <p style={{fontSize:"30px"}} className="text-muted mb-0">{weatherData?.location.name}</p>
                  {/* <div>
                    <img
                      src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-weather/ilu3.webp"
                      width="150px"
                    />
                  </div> */}
                </div>
              </MDBCardBody>
            </MDBCard>

            {/* <MDBCard className="mb-4" style={{ borderRadius: "25px" }}>
              <MDBCardBody className="p-4">
                <div className="d-flex justify-content-around text-center pb-3 pt-2">
                  <div className="flex-column">
                    <p className="small">
                      <strong>{weatherData?.currentConditions.temp}</strong>
                    </p>
                    <MDBIcon
                      fas
                      icon="sun"
                      size="2x"
                      className="mb-3"
                      style={{ color: "#ddd" }}
                    />
                    <p className="mb-0">
                      <strong>12:00</strong>
                    </p>
                    <p
                      className="mb-0 text-muted"
                      style={{ fontSize: ".65rem" }}
                    >
                      PM
                    </p>
                  </div>
                  <div className="flex-column">
                    <p className="small">
                      <strong>1°C</strong>
                    </p>
                    <MDBIcon
                      fas
                      icon="sun"
                      size="2x"
                      className="mb-3"
                      style={{ color: "#ddd" }}
                    />
                    <p className="mb-0">
                      <strong>1:00</strong>
                    </p>
                    <p
                      className="mb-0 text-muted"
                      style={{ fontSize: ".65rem" }}
                    >
                      PM
                    </p>
                  </div>
                  <div className="flex-column">
                    <p className="small">
                      <strong>2°C</strong>
                    </p>
                    <MDBIcon
                      fas
                      icon="cloud"
                      size="2x"
                      className="mb-3"
                      style={{ color: "#ddd" }}
                    />
                    <p className="mb-0">
                      <strong>2:00</strong>
                    </p>
                    <p
                      className="mb-0 text-muted"
                      style={{ fontSize: ".65rem" }}
                    >
                      PM
                    </p>
                  </div>
                  <div className="flex-column">
                    <p className="small">
                      <strong>1°C</strong>
                    </p>
                    <MDBIcon
                      fas
                      icon="cloud"
                      size="2x"
                      className="mb-3"
                      style={{ color: "#ddd" }}
                    />
                    <p className="mb-0">
                      <strong>3:00</strong>
                    </p>
                    <p
                      className="mb-0 text-muted"
                      style={{ fontSize: ".65rem" }}
                    >
                      PM
                    </p>
                  </div>
                  <div className="flex-column">
                    <p className="small">
                      <strong>2°C</strong>
                    </p>
                    <MDBIcon
                      fas
                      icon="cloud-showers-heavy"
                      size="2x"
                      className="mb-3"
                      style={{ color: "#ddd" }}
                    />
                    <p className="mb-0">
                      <strong>4:00</strong>
                    </p>
                    <p
                      className="mb-0 text-muted"
                      style={{ fontSize: ".65rem" }}
                    >
                      PM
                    </p>
                  </div>
                </div>
              </MDBCardBody>
            </MDBCard> */}
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </section>
    </div>
  );
};

export default PagePogoda;
