import React from "react";
import MainNav1 from "./MainNav1";
import MainNav2 from "./MainNav2"
import MainNavLogged from "./MainNav2Logged";

const Header2 = () => {
  const user = sessionStorage.getItem("user")
  return (
    <div className="nc-Header2 top-0 w-full z-40">
      <MainNav1/>
    </div>
  );
};

export default Header2;
