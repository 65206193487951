import React from "react";
import logo from "../../images/logo.png"

const LogoSvg = () => {
  return (
    <img
    // style={{paddingTop:"20px"}}
    alt="logo-orginal"
    src={logo}
      width="330"
      height="41"/>
  );
};

export default LogoSvg;
