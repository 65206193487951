import React, { FC } from "react";
import NcImage from "components/NcImage/NcImageAds";
import { TaxonomyType } from "data/types";
import Link from "components/Link";


const CardCategory1 = ({
  className = "",
  size = "normal",
  id,
  category,
  category_id,
  subcategory_id,
  subcategory,
  image,
  name,
}) => {
  return (
    <Link
      to={`/${subcategory}/${category}/${category_id}/${subcategory_id}/artykuł/${id}`} title={name}
      className={`nc-CardCategory1 flex items-center ${className}`}
    >
      <NcImage
        alt=""
        style={{width:'60px', height:'60px', objectFit:'cover', marginRight:"1rem"}}
        containerClassName={`relative flex-shrink-0  overflow-hidden`}
        src={image}
        // fill
        className="object-cover"
        sizes="380px"
      />
      <div>
      <Link href={`/${subcategory}/${category.toLowerCase()}/${category_id}/${subcategory_id}/artykuł/${id}`} title={name}
    >
        <h2
        style={{fontSize:"0.8rem", lineHeight:"15px"}}
          className={`${
            size === "large" ? "text-lg" : "text-base"
          } nc-card-title text-neutral-900 dark:text-neutral-100 text-sm sm:text-base font-medium sm:font-semibold`}
        >
          {name}
        </h2>
        </Link>
        <span 
          className={`${
            size === "large" ? "text-sm" : "text-xs"
          } block mt-[2px] text-neutral-500 dark:text-neutral-400`}
        >
          {category}
        </span>
      </div>
    </Link>
  );
};

export default CardCategory1;
