import React from "react";
import logo from "../../images/logo_biale_zloty.png"

const LogoFooter = () => {
  return (
    <img
    style={{paddingTop:"20px"}}
    alt="logo-orginal"
    src={logo}
      width="330"
      height="41"/>
  );
};

export default LogoFooter;
