import React, { FC } from "react";
import { PostDataType } from "data/types";
import CategoryBadgeList from "components/CategoryBadgeList/CategoryBadgeList";
import PostTypeFeaturedIcon from "components/PostTypeFeaturedIcon/PostTypeFeaturedIcon";
import Link from "components/Link";
import Image from "components/Image";

// export interface Card8Props {
//   className?: string;
//   post: PostDataType;
// }

const Card8Special = ({ className = "h-full", category, post , title, name, subcategory_id, category_id }) => {
  if (!post) {
    return null;
  }
  const { href, desc, date, categories, postType } = post;


  return (
    <div
      className={`nc-Card8 group relative overflow-hidden z-0 ${className}`}
    >
      <Link
        href={`/${post?.title.toLowerCase()}/${post?.name.toLowerCase()}/${post?.category_id}/${post?.subcategory_id}/artykuł/${post?.id}`}
        className="w-full h-0 pt-[100%] sm:pt-[55%] overflow-hidden z-0"
      >
        <img
          // className="object-cover"
          src={post?.image}
          // alt={post.title}
          loading="lazy"
          // style={{width:"614px", height:'337px'}}
          width={614}
          height={337}
          // fill
          sizes="(max-width: 600px) 480px, 800px"
        />
        <PostTypeFeaturedIcon
          className="absolute top-4 left-4"
          // postType={postType}
          wrapSize="w-8 h-8"
          iconSize="w-4 h-4"
        />
      </Link>
      <Link
        href={`/single/${post.id}`}
        style={{opacity:"1.6"}}
        // className="absolute inset-x-0 bottom-0 top-1/3 hover:top-5 bg-gradient-to-t from-black group-hover:opacity-70 transition-opacity"
      ></Link>
      {/* <div className="absolute inset-x-0 bottom-0 p-4 sm:p-6 flex flex-col">
        <div className="hidden sm:block mt-2">
        </div>
      </div> */}
    </div>
  );
};

export default Card8Special;
