import React, { FC } from "react";
import { PostDataType } from "data/types";
import CategoryBadgeList from "components/CategoryBadgeList/CategoryBadgeList";
import PostTypeFeaturedIcon from "components/PostTypeFeaturedIcon/PostTypeFeaturedIcon";
import Link from "components/Link";
import Image from "components/Image";

// export interface Card8Props {
//   className?: string;
//   post: PostDataType;
// }

const Card8 = ({ className = "h-full", category, post }) => {
  const { title, href, desc, date, categories, postType } = post;


  return (
    <div
      className={`nc-Card8 group relative overflow-hidden z-0 ${className}`}
    >
      <Link
       href={`/single/${post.id}`}
        className="w-full h-0 pt-[100%] sm:pt-[55%] overflow-hidden z-0"
      >
        <Image
          className="object-cover"
          src={post.image}
          alt={post.title}
          fill
          sizes="(max-width: 600px) 480px, 800px"
        />
        <PostTypeFeaturedIcon
          className="absolute top-4 left-4"
          // postType={postType}
          wrapSize="w-8 h-8"
          iconSize="w-4 h-4"
        />
      </Link>
      <Link
        href={`/single/${post.id}`}
        style={{opacity:"1.6"}}
        className="absolute inset-x-0 bottom-0 top-1/3 hover:top-5 bg-gradient-to-t from-black group-hover:opacity-70 transition-opacity"
      ></Link>
      <div className="absolute inset-x-0 bottom-0 p-4 sm:p-6 flex flex-col">
        <Link href={`/single/${post.id}`}className="absolute inset-0" />
        {/* <h2
          className={`mt-3 relative block font-semibold text-neutral-50 text-lg sm:text-2xl`}
        >
          <Link href={`/single/${post.id}`} className="line-clamp-3" title={post.title}>
            {post.article_title}
          </Link>
        </h2> */}
        {/* <p style={{color:"white"}}>{post.description.slice(0,20)}...</p> */}
        <div className="hidden sm:block mt-2">
        {/* <Link href={`/single/${post.id}`} className="line-clamp-3" title={post.title}>
        <span style={{color:"white"}} className="mx-[6px] font-medium">Zobacz</span>
        <span style={{color:"white"}} className="font-normal truncate">{new Date(date).toLocaleString()}</span>
        </Link> */}
        {/* <CategoryBadgeList categories={post}/> */}
          {/* <span className="text-neutral-300 text-sm line-clamp-1"></span> */}
        </div>
      </div>
    </div>
  );
};

export default Card8;
