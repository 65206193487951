import { Popover, Tab, Transition } from "app/headlessui";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import { GlobeAltIcon } from "@heroicons/react/24/outline";
import { FC, Fragment, useEffect, useRef, useState } from "react";
import { headerCurrency } from "./CurrencyDropdown";
import { BACKEND_URL } from "../../constants"
import { Link } from "react-router-dom"

const LangDropdown = ({ panelClassName = "" , id,  category}) => {
  const [subcategories,setSubcategories] = useState([])
  const initialized = useRef(false);
  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      fetch(`${BACKEND_URL}/subcategories`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((response) => {
          if (!response.hasOwnProperty("error")) {
           setSubcategories(response.result)
          }
        })
        .catch((error) => {
          console.error("error: ", error);
        })
        .finally(() => {
          console.log("finally")
        })
    }
  }, []);

  const renderLang = (close) => {
    return (
      <div  style={{grid:"auto / auto auto auto auto"}} className="grid gap-8">
        {subcategories.map((item, index) => (
          <a
            key={index}
            href={`/${item.title.toLowerCase()}/${category.toLowerCase()}/${id}/${item.id}`}
            onClick={close}

            className={`flex items-center p-2 -m-3 transition duration-150 ease-in-out rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50`}
          >
            <div>
              <p className="text-sm font-medium ">{item.title}</p>
            </div>
          </a>
        ))}
      </div>
    );
  };

  return (
    <div className="LangDropdown hidden md:block">
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
            style={{color:"black",}}
              className={`
                ${open ? "" : "text-opacity-80"}
             group h-10 sm:h-12 px-3 py-1.5 inline-flex items-center text-sm text-gray-800 dark:text-neutral-200 font-medium hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
            >
              {/* <GlobeAltIcon className="w-[18px] h-[18px] opacity-80" /> */}
              <Link to={`/${category.toLowerCase()}/${parseInt(id)}`}><span>{category}</span></Link>
              <ChevronDownIcon
                className={`${open ? "-rotate-180" : "text-opacity-70"}
                  ml-1 h-4 w-4  group-hover:text-opacity-80 transition ease-in-out duration-150`}
                aria-hidden="true"
              />
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel
                className={`absolute z-20 w-96 left-0 ${panelClassName}`}
              >
                <div className="dark:bg-neutral-800 shadow-lg ring-1 ring-black ring-opacity-5">
                  <Tab.Group>
                    {/* <Tab.List className="flex space-x-1 bg-gray-100 dark:bg-slate-700 p-1">
                      {[category].map((category) => (
                        <Tab
                          key={category}
                          className=
                              "w-full py-2 text-sm font-medium leading-5 text-gray-700">
                          {category}
                        </Tab>
                      ))}
                    </Tab.List> */}
                    <Tab.Panels 
                    // className="mt-5"
                    >
                      <Tab.Panel
                        className=
                          "p-3"
                      >
                        {renderLang(close)}
                      </Tab.Panel>
                      {/* <Tab.Panel
                        className={classNames(
                          "rounded-xl p-3",
                          "focus:outline-none focus:ring-0"
                        )}
                      >
                        {renderCurr(close)}
                      </Tab.Panel> */}
                    </Tab.Panels>
                  </Tab.Group>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  );
};
export default LangDropdown;
