import React, { FC, useEffect, useRef, useState } from "react";
import Card8 from "components/Card8/Card8";
import { SectionMagazine1Props } from "./SectionMagazine1";
import HeaderFilter from "./HeaderFilter";
import Card9 from "components/Card9/Card9";
import { BACKEND_URL } from "constants"
import Card8Special from "components/Card8Special/Card8Special"
import { Link } from "react-router-dom"

// export interface SectionMagazine4Props extends SectionMagazine1Props {}

const SectionMagazineFirst = ({
  posts,
  className = "",
  category_id,
  category,
  subcategory_id,
  heading = "Latest Articles 🎈",
}) => {
  const filtered = posts.filter((post) => post.category_id === 5)
    .sort(() => function(a, b){return a - b})

  // const postWithStatus1 = posts
  //   .find((item) => item.category_id === category_id && item.subcategory_id === subcategory_id && item.status === 1);

  //   const postWithStatus2 = posts
  //   .find((item) => item.category_id === category_id && item.subcategory_id === subcategory_id && item.status === 2);

  return (
      <div className={`nc-SectionMagazine4 ${className}`}>
        {filtered
        .slice(filtered.length - 1, filtered.length)
        .map((post) => {
          return(
            <div key={post.id}>
              <h2 style={{marginBottom:"0px" ,fontSize:"28px", marginTop:"8px"}}
          className={`mt-3 relative block font-semibold text-neutral-50 text-lg sm:text-2xl`}
        >
          <Link style={{color:"black"}} to={`/${post?.title.toLowerCase()}/${post?.name.toLowerCase()}/${post?.category_id}/${post?.subcategory_id}/artykuł/${post?.id}`} className="line-clamp-3" title={post?.article_title}>
            {post.article_title}
          </Link>
        </h2>
        <div style={{display:"flex", flexFlow:"column", gap:"13px"}}>
          <span style={{color:"red",fontWeight:700, margin:"4px 0 4px 0"}}><b>{post?.title}</b></span>
          <p style={{color:"black", textAlign:"justify"}}>{post?.long_description.slice(0,146)}...</p>
          <Card8Special category={category} title={post?.title.toLowerCase()} name={post?.name.toLowerCase()} category_id={post?.category_id} subcategory_id={post?.subcategory_id} className="sm:col-span-2" post={post} />
        </div>
            </div>
          )})}
  
        {!posts?.length && <span>Nie znaleziono artykułów!</span>}
      </div>
    );
};

export default SectionMagazineFirst;
