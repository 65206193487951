import { Slide } from 'react-slideshow-image';
import kuchnia from "../../../images/kuchniaregionalna.jpg";
import zurek from "../../../images/zorek.jpeg";
import roladka from "../../../images/roladaslaska.jpeg";
import 'react-slideshow-image/dist/styles.css'
import { Link } from 'react-router-dom'
import HeaderFilter from 'components/Sections/HeaderFilter'

const PageKuchnia = () => {

  const divStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundSize: 'cover',
    height: '250px'
  }

  const slideImages = [
    // {
    //   url: 'https://images.unsplash.com/photo-1509721434272-b79147e0e708?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1500&q=80',
    //   caption: "Horoskop"
    // },
    // {
    //   url:"https://i.ibb.co/LttdKXB/pexels-sharefaith-491036-1202723.jpg",
    //   caption: "Horoskop"
    // },
    {
      url: kuchnia,
      caption: "Kuchnia regionalna"
    },
  ]; 


//   useEffect(() => {
//     if (!initialized.current) {
//       initialized.current = true;
//       fetch(`${BACKEND_URL}/subcategories`, {
//         method: "GET",
//         headers: {
//           "Content-Type": "application/json",
//         },
//       })
//         .then((response) => response.json())
//         .then((response) => {
//           if (!response.hasOwnProperty("error")) {
//            setData(response.result)
//           }
//         })
//         .catch((error) => {
//           console.error("error: ", error);
//         })
//         .finally(() => {
//           console.log("finally")
//         })
//     }
//   }, []);

  return (
    <div className={`nc-PageArchiveAudio `}>
      {/* HEADER */}
      <div className="w-full px-2 xl:max-w-screen-2xl mx-auto">
      <div className="container" style={{marginTop:"37px", textAlign:"left"}}>
        <HeaderFilter heading="Kuchnia Regionalna" />
        </div>
      </div>
      {/* ====================== END HEADER ====================== */}

      <div className="container lg:pb-28 space-y-16 lg:space-y-28">
        <div>
          <div className="flex flex-col sm:items-center sm:justify-between sm:flex-row">
            <div className="block my-4 border-b w-full border-neutral-300 dark:border-neutral-500 sm:hidden"></div>
          </div>
            <div className="kuchnia-box">
            <div className="kuchnia-container">
            <div className="kitchen-content">
              <div style={{display:"flex", flexFlow:"column", gap:"20px", alignItems:"baseline", textAlign:"left"}}>
              <Link to="/artykuł/88"><h2 style={{fontSize:"32px"}} className="inline-block align-middle font-semibold ">Żurek</h2></Link>
            <p style={{maxWidth:"318px", textAlign:"justify"}}>
Żurek to tradycyjna polska zupa, która ma długą historię i jest szczególnie popularna w regionach wiejskich, w tym w województwie opolskim.</p>
              </div>
            <Link to="/artykuł/88">
              <img alt="żurek" style={{maxWidth:'249px', height:"163px"}}src={zurek}/>
              </Link>
              </div>
            </div>
            <div className="kuchnia-container">
            <div className="kitchen-content">
            <div style={{display:"flex", flexFlow:"column", gap:"20px", alignItems:"baseline", textAlign:"left"}}>
              <Link to="/artykuł/87">
                <h2 style={{maxWidth:"400px",fontSize:"24px"}} className="inline-block align-middle font-semibold ">
                  Roladka śląska
                  </h2>
                  </Link>
                  <p style={{maxWidth:"318px", textAlign:"justify"}}>
Żurek to tradycyjna polska zupa, która ma długą historię i jest szczególnie popularna w regionach wiejskich, w tym w województwie opolskim.</p>
                  </div>
                  <Link to="/artykuł/87">
                  <img alt="roladka" style={{maxWidth:'249px', height:"163px"}} src={roladka}/>
                  </Link>
            </div>
            </div>
            </div>
            

          {/* <SectionMagazineCategories
            category={params.category}
            category_id={params.id}
            posts={data}
          /> */}

        </div>

        {/* <div className="relative py-16">
          <BackgroundSection />
        </div> */}
      </div>
    </div>
  );
};

export default PageKuchnia
