import Card8Special from "components/Card8Special/Card8Special"
import { Link } from "react-router-dom"

const WidgetCategoriesThird = ({
  className = "bg-neutral-105",
  categories,
  posts,
}) => {
  return (
    <div
      className={`nc-WidgetCategories overflow-hidden`}
    >
      <div className="flow-root">
        <div className="flex flex-col divide-y divide-neutral-200 dark:divide-neutral-700">
        {/* <WidgetHeading1
        title="Polecane artykuły"
        viewAll={{ label: "Zobacz wszystkie", href: "/#" }}
      /> */}
          {/* {posts.filter((_, i,) => i < 13 && i >= 12)
          .map((item,index) => ( */}
            <div style={{display:"flex", flexFlow:"column", alignItems:"baseline", gap:"29px"}}>
            <div style={{display:"flex", flexFlow:'column', gap:"10px"}}>
            <Link to={`/${posts[99]?.title.toLowerCase()}/${posts[99]?.name.toLowerCase()}/${posts[99]?.category_id}/${posts[99]?.subcategory_id}/artykuł/${posts[99]?.id}`}style={{color:"black"}}>
            <h3 style={{fontSize: "24px", fontWeight:"bold", maxWidth:"400px"}}>{posts[99]?.article_title}</h3>
            </Link>
            <span style={{color:"red",fontWeight:700}}><b>{posts[99]?.title}</b></span>
            <p style={{maxWidth:"440px"}}>{posts[99]?.long_description.slice(0,200)}...</p>
            </div>
            <Card8Special key={posts[99]?.id} post={posts[99]} />
          </div>
          {/* ))} */}
        </div>
      </div>
    </div>
  );
};

export default WidgetCategoriesThird;
