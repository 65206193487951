import React, { FC, useEffect, useRef, useState } from "react";
import Card8 from "components/Card8/Card8";
import { SectionMagazine1Props } from "./SectionMagazine1";
import HeaderFilter from "./HeaderFilter";
import Card9 from "components/Card9/Card9";
import { BACKEND_URL } from "constants"
import { Link } from "react-router-dom"

// export interface SectionMagazine4Props extends SectionMagazine1Props {}

const SectionMagazine4 = ({
  posts,
  className = "",
  category_id,
  category,
  subcategory_id,
  heading = "Latest Articles 🎈",
}) => {

  // const postWithStatus1 = posts
  //   .find((item) => item.category_id === category_id && item.subcategory_id === subcategory_id && item.status === 1);

  //   const postWithStatus2 = posts
  //   .find((item) => item.category_id === category_id && item.subcategory_id === subcategory_id && item.status === 2);

  return (
      <div className={`nc-SectionMagazine4 ${className}`}>
        <HeaderFilter heading={heading} />
  
        {!posts?.length && <span>Nothing we found!</span>}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-6 md:gap-8">
          {/* {posts[8] && 
           <div style={{display:"flex", flexFlow:"column", alignItems:"baseline", gap:"10px"}}>
           <div style={{width:'536px'}}>
           <h2><b>{posts[8]?.title}</b></h2>
           <p>{posts[8]?.description.slice(0.90)}</p>
           </div>
           <Card8 category={category} className="sm:col-span-2" post={posts[8]} />
           </div>
          } */}
          {posts
            .filter((_, i) => i < 107 && i > 100)
            .map((item, index) => {
            return (
              <div key={item.id} style={{display:"grid", alignContent: 'space-between'}}>
                <div style={{maxWidth:"400px",display:"flex", flexFlow:'column', gap:"10px"}}>
                <Link to={`/${item?.title.toLowerCase()}/${item?.name === "Świat" ? "swiat" : item?.name.toLowerCase()}/${item?.category_id}/${item?.subcategory_id}/artykuł/${item?.id}`} style={{color:"black"}}>
                <h3 style={{fontSize: "24px", fontWeight:"bold"}}>{item?.article_title}</h3>
                </Link>
                <span style={{color:"red",fontWeight:700}}><b>{item?.title}</b></span>
                <p>{item?.description.slice(0,70)}...</p>
                </div>
                <Card9 key={item.id} post={item} />
              </div>
            )})}
            {/* {posts[10] && <Card8 category={category} className="sm:col-span-2" post={posts[10]} />} */}
        </div>
      </div>
    );
};

export default SectionMagazine4;
