import React, { HTMLAttributes, ReactNode } from "react";

export interface HeadingProps extends HTMLAttributes<HTMLHeadingElement> {
  fontClass?: string;
  desc?: ReactNode;
  isCenter?: boolean;
}

const Heading: React.FC<HeadingProps> = ({
  children,
  desc = "",
  className = "mb-10 md:mb-12 text-neutral-900 dark:text-neutral-50",
  isCenter = false,
  ...args
}) => {
  return (
    <div 
    style={{marginTop:"2rem"}}
      className={`nc-Section-Heading relative flex flex-col sm:flex-row sm:items-end justify-between ${className}`}
    >
      <div
      style={{width:"100%"}}
        className={
          isCenter ? "text-center w-full max-w-2xl mx-auto" : ""
        }
      >
        <h2
          className={`text-2xl md:text-3xl lg:text-4xl font-semibold`}
          {...args}
        >
          {children || `Section Heading`}
        </h2>
      {children !== "Ostatnie artykuły wideo" && children !== "Felietony" && children !== "Zobacz też" && children !== "Polecane artykuły" && children !== "POLITYKA" && <hr style={{padding:"1px", backgroundColor:"#ac8502"}}></hr>}
      {children === "POLITYKA" && <hr style={{padding:"1px", backgroundColor:"#ac8502"}}></hr> }
        {/* {desc && (
          <span className="mt-2 md:mt-3 font-normal block text-base sm:text-xl text-neutral-500 dark:text-neutral-400">
            {desc}
          </span>
        )} */}
      </div>
    </div>
  );
};

export default Heading;
