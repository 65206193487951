import YouTube from 'react-youtube';

const WidgetVideo = ({
  className = "bg-neutral-100 dark:bg-neutral-800",
  categories,
  height,
  width,
  posts,
}) => {
  const opts = {
    height: height,
    width: width,
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
    },
  };
  return (
    <div
      className={`nc-WidgetCategories overflow-hidden ${className}`}
    >
      <div className="flow-root">
        <div className="flex flex-col divide-y divide-neutral-200 dark:divide-neutral-700">
          <div style={{display:"flex", flexFlow:"column", alignItems:'center'}}>
          {/* <button style={{marginBottom:'20px'}}>Strefa oglądania i czytania</button> */}
          {/* <YouTube videoId="NJ1HdjufmCA" opts={opts}/> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WidgetVideo;