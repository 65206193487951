import PageContact from "app/(others)/contact/page"
import HeaderFilter from "components/Sections/HeaderFilter"


const PageRodo = () => {
  return (
    <div style={{paddingBottom:"40px"}} className={`nc-PageAbout relative container`}>
    <HeaderFilter heading="Polityka prywatności"/>
    {/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p> */}
      <div>
        <p style={{marginBottom:"6px"}}>Mamy świadomość jak istotne są Twoje dane osobowe i dlatego ich ochrona jest dla nas priorytetem.
Podejmujemy najwyższe starania, aby gromadzone dane o naszych Użytkownikach a także dane osobowe
innych osób, z którymi współpracujemy i które są naszymi pracownikami były przetwarzane z
poszanowaniem obowiązujących przepisów zarówno polskich jak i Unii Europejskiej.</p>
<p style={{marginBottom:"10px"}}>W niniejszym dokumencie znajdziesz informacje o tym w jakim celu i jak zbierane i przetwarzane są Twoje
dane osobowe, jak długo są one przetwarzane oraz jakie prawa przysługują naszym Użytkownikom w
związku z przetwarzaniem ich danych.</p>

       <h3 style={{marginBottom:"10px"}}><b>§1. DEFINICJE</b></h3>
       <p style={{marginBottom:"10px"}}>Co rozumiemy przez poniższe określenia:</p>
       <p style={{marginBottom:"10px"}}>Administrator danych – to podmiot, który samodzielnie lub wspólnie z innymi ustala cele i sposoby
przetwarzania danych osobowych. W przypadku danych gromadzonych za pośrednictwem portalu
internetowego Respublica.com.pl Administratorem danych osobowych jest spółka wskazana w § 2
niniejszej Polityki Prywatności.</p>
<p style={{marginBottom:"10px"}}>Użytkownik - każda osoba korzystająca z portalu internetowego RESPUBLICA.COM.PL oraz z
Newslettera dostarczanego przez Administratora</p>

<p style={{marginBottom:"10px"}}>Dane osobowe - to wszelkie informacje o zidentyfikowanej lub możliwej do zidentyfikowania osobie
fizycznej, czyli takiej, którą można bezpośrednio lub pośrednio zidentyfikować, w szczególności na
podstawie identyfikatora takiego jak imię i nazwisko, numer identyfikacyjny, dane o lokalizacji,
identyfikator internetowy lub jeden bądź kilka szczególnych czynników określających fizyczną,
fizjologiczną, genetyczną, psychiczną, ekonomiczną, kulturową lub społeczną tożsamość osoby fizycznej.</p>

<p style={{marginBottom:"10px"}}>Przetwarzanie danych - to operacja lub zestaw operacji wykonywanych na danych osobowych lub
zestawach danych osobowych w sposób zautomatyzowany lub niezautomatyzowany, taka jak zbieranie,
utrwalanie, organizowanie, porządkowanie, przechowywanie, adaptowanie lub modyfikowanie, pobieranie,
przeglądanie, wykorzystywanie, ujawnianie poprzez przesłanie, rozpowszechnianie lub innego rodzaju
udostępnianie, dopasowywanie lub łączenie, ograniczanie, usuwanie lub niszczenie.</p>

<p style={{marginBottom:"10px"}}>Portal Internetowy – portal internetowy Respublica.com.pl dostępny pod adresem internetowym:
www.respublica.com.pl</p>

<p style={{marginBottom:"10px"}}>Formularz kontaktowy - umieszczona w Portalu Internetowym funkcjonalność przeznaczona dla
Użytkowników, umożliwiająca wysłanie wiadomości do Usługodawcy z pominięciem zewnętrznej poczty
elektronicznej.</p>

<p style={{marginBottom:"10px"}}>Pliki Cookies - to pliki zawierające krótkie informacje tekstowe zapisywane przez serwer na urządzeniu
Użytkownika, które następnie wykorzystywane są przy ponownym połączeniu się Użytkownika z Portalem
Internetowym. Pliki cookies służą do zapamiętywania preferencji Użytkowników i wybranych ustawień, a
także zbierają anonimowo dane służące do celów reklamowych i statystycznych.</p>

<p style={{marginBottom:"10px"}}>RODO - Rozporządzenie Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w
sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie
swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o
ochronie danych) z dnia 27 kwietnia 2016 r.</p>

<p style={{marginBottom:"10px"}}>Ustawa o ochronie danych osobowych - Ustawa o ochronie danych osobowych z dnia 10 maja 2018 r.</p>
<h3 style={{marginBottom:"10px"}}><b>§ 2. ADMINISTRATOR DANYCH OSOBOWYCH</b></h3>
<h4>Kto jest administratorem Twoich danych?</h4>
<p style={{marginBottom:"10px"}}>Administratorem Twoich danych osobowych jest WORLD DEVELOPMENTS Sp. z o.o. z siedzibą w
Nysie (48-303), ul. Podolska 22, wpisana do Rejestru Przedsiębiorców przez Sąd Rejonowy w Opolu, VIII
Wydział Gospodarczy Krajowego Rejestru Sądowego pod numerem KRS: 0000572761, o kapitale
zakładowym w wysokości 205.000 zł, posiadająca numer NIP: 7532438114.</p>
<h4>Jak skontaktować się z Administratorem danych?</h4>
<p style={{marginBottom:"10px"}}>We wszelkich sprawach związanych z przetwarzaniem danych osobowych możesz się skontaktować z
wyznaczonym przedstawicielem Administratora, którym jest WORLD DEVELOPMENTS Sp. z o.o,
będący inspektorem ochrony danych osobowych, pisząc do niego: na adres mailowy:rodo@respublica.com.pl pod adres: ul. Podolska 22
Nysa</p>
<h3 style={{marginBottom:"10px"}}><b>§3. RODZAJ I SPOSÓB POZYSKANIA PRZETWARZANYCH DANYCH</b></h3>
<h4>Jakie dane gromadzimy i w jaki sposób je pozyskujemy?</h4>

<p style={{marginBottom:"10px"}}>W zależności od tego w jaki sposób korzystasz z usług świadczonych przez nas, albo jaka umowa nas
wiąże, możemy gromadzić różne dane dotyczące Twojej osoby.</p>

<p style={{marginBottom:"10px"}}>Gdy odwiedzasz nasz Portal Internetowy, pliki cookies przechowywane na Twoim urządzeniu lub inna
podobnie działająca technologia może gromadzić Twoje dane w postaci adresu IP, informacje o
odwiedzanych stronach, w tym poszczególnych artykułach lub innych materiałach publikowanych w
Portalu Internetowym, Twojej aktywności w systemie, posiadanej przeglądarce internetowej, urządzeniu
końcowym czy lokalizacji.</p>

<p style={{marginBottom:"10px"}}>Gdy korzystasz z naszej usługi Newsletter albo z Formularza Kontaktowego gromadzimy dane
niezbędne do przesyłania Ci korespondencji mailowej, w szczególności Twój adres e-mail. Możemy
również prosić Cię o podanie imienia, celem przesyłania Ci spersonalizowanych wiadomości. Dane te
podajesz nam dobrowolnie, zapisując się do Newslettera lub wypełniając Formularz Kontaktowy.</p>

<p style={{marginBottom:"10px"}}>W przypadku składania do nas reklamacji pozyskujemy Twoje dane niezbędne do rozpatrzenia
reklamacji i udzielenia odpowiedzi na nią, takie jak imię i nazwisko, adres korespondencyjny, adres
mailowy.
Dane te podajesz nam dobrowolnie, składając reklamację.</p>

<p style={{marginBottom:"10px"}}>Gdy zamierzasz zawrzeć umowę z Administratorem, zarówno jako klient, ale także jako
współpracownik, pracownik czy podwykonawca, przed jej zawarciem pozyskujemy Twoje dane
niezbędne do jej zawarcia, jak również do jej wykonania, takie jak: dane mające na celu potwierdzenie
tożsamości: imię i nazwisko, numer PESEL, numer NIP, numer dowodu tożsamości, datę urodzenia i
inne. Dane te wskazujesz nam dobrowolnie przed zawarciem umowy, wyrażając zgodę na ich
przetwarzanie.</p>
<h3 style={{marginBottom:"10px"}}><b>§4. CEL I PODSTAWA PRAWNA PRZETWARZANIA DANYCH OSOBOWYCH</b></h3>
<p style={{marginBottom:"10px"}}>Dane osobowe gromadzone przez nas przetwarzane są w następujących celach i na następującej podstawie
prawnej:</p>
<ul style={{listStyleType:'circle'}}>
  <li>w celu świadczenia przez nas usługi Newslettera – podstawą prawną przetwarzania Twoich
danych jest w takim wypadku ich niezbędność prawidłowego wykonania przez nas umowy
zawartej z Tobą jako subskrybenta Newslettera (art. 6 ust.1 pkt b RODO),</li>

<li>w celu obsługi Formularza Kontaktowego dostępnego na stronie internetowej – podstawą prawną
przetwarzania Twoich danych jest udzielona przez Ciebie zgoda (art. 6 ust. 1 lit a) RODO)., którą
może w każdym czasie wycofać,</li>

<li>w celach rekrutacji do pracy – podstawą przetwarzania Twoich danych jest udzielona przez Ciebie
zgoda (art. 6 ust. 1 lit a) RODO)., którą może w każdym czasie wycofać,</li>

<li>w celu zatrudnienia na podstawie umowy o pracę i na innej podstawie, jak np. umowa zlecenia czy
umowa o dzieło – podstawą przetwarzania Twoich danych jest: konieczność zawarcia i wykonania
umowy (art. 6 ust. 1 lit. b RODO),</li>

<li>w celach statystycznych i analitycznych, w celu zapewnienia bezpieczeństwa świadczonych usług
oraz w celu reklamy i promocji własnych usług – podstawą prawną przetwarzania Twoich danych
gromadzonych w tych celach jest nasz uzasadniony interes (art. 6 ust. 1 lit. f) RODO), polegający
na analizie i prowadzeniu statystyk danych dotyczących preferencji Użytkowników i ich
aktywności w Portalu Internetowym, których celem jest poprawa jakości, wygody i
bezpieczeństwa naszych Usług oraz zwiększenie funkcjonalności Portalu Internetowego.</li>

<li>w celu marketingu usług i produktów oferowanych przez podmioty trzecie – podstawą
przetwarzania Twoich danych osobowych w tych celach jest udzielenie przez Ciebie dobrowolnej
zgody (art. 6 ust. 1 lit a) RODO). Możesz wycofać udzieloną zgodę w każdym momencie lub
ograniczyć zakres udzielonej zgody.</li>

<li>w celu ustalenia i dochodzenia roszczeń wynikających z prowadzonej działalności gospodarczej
lub obrony praw – na podstawie art. 6 ust. 1 pkt f RODO,</li>

<li style={{marginBottom:"10px"}}>w celu wypełnienia ciążących na nas obowiązków prawnych, w szczególności: podatkowych,
rachunkowych, obowiązkach ciążących na nas jako pracodawcy, rozpatrywania reklamacji,
udzielenia odpowiedzi na zapytania uprawnionych organów – na podstawie art. 6 ust. 1 pkt c)
RODO.</li>
</ul>
<h3 style={{marginBottom:"10px"}}><b>§5. OKRES PRZECHOWYWYANIA DANYCH</b></h3>
<p style={{marginBottom:"10px"}}>Jak długo przechowujemy Twoje dane osobowe?
Zależy to od rodzaju danych i celu w jakim je przetwarzamy, jednak przechowujemy je nie dłużej niż
istnieje podstawa prawna do tego.</p>
<p style={{marginBottom:"10px"}}>W przypadku danych osobowych na których wykorzystanie udzieliłeś zgody i brak jest innej podstawy ich
przetwarzania – przetwarzamy je do czasu wycofania przez Ciebie takiej zgody.</p>
<p style={{marginBottom:"10px"}}>Dane osobowe przetwarzane w celu zawarcia i wykonania zawartej umowy przetwarzamy przez okres jej
obowiązywania a następnie przez okres przedawnienia roszczeń wynikających z zawartej umowy,
wynikający z obowiązujących przepisów prawa.</p>
<p style={{marginBottom:"10px"}}>Dane wykorzystywane w uzasadnionym interesie Administratora przetwarzane mogą być do czasu
wyrażenia przez Ciebie sprzeciwu co do ich przetwarzania.</p>
<p style={{marginBottom:"10px"}}>Dane przetwarzane w celu wypełnienia ciążących na Administratorze obowiązków prawnych są
przechowywane przez okres wymagany przez obowiązujące przepisy.</p>
<h3 style={{marginBottom:"10px"}}><b>§6. UDOSTĘPNIANIE DANYCH</b></h3>
<h4>Komu udostępniamy Twoje dane osobowe?</h4>
<ul style={{listStyleType:'circle'}}>
  <li>Twoje dane osobowe mogą być udostępniane w niezbędnym zakresie innym osobom i
podmiotom świadczącym usługi na rzecz Administratora, które przetwarzać je będą w imieniu
Administratora, na podstawie zawartej z nim umowy o ich przetwarzanie, takim jak np. agencje
marketingowe, firmy świadczące usługi techniczne, teleinformatyczne, księgowo-kadrowe oraz
prawne i podatkowe – wyłącznie w zakresie niezbędnym do prawidłowego świadczenia tych
usług,</li>

<li>Twoje dane osobowe mogą być także przekazywane bankom i przedsiębiorstwom umożliwiającym
nam rozliczenia bezgotówkowe – wyłącznie w zakresie niezbędnym do obsługi transakcji
finansowych,</li>

<li>Jeśli wyraziłeś zgodę na przetwarzanie swoich danych w celach marketingowych, w tym na
udostępnianie swoich danych podmiotom współpracującym z Administratorem, Twoje dane
mogą być przez nas udostępnione takim podmiotom.</li>

<li style={{marginBottom:"10px"}}>Twoje dane mogą również zostać udostępnione upoważnionym organom na podstawie
obowiązujących przepisów.</li>
</ul>
<h3 style={{marginBottom:"10px"}}><b>§7. PRZEKAZYWANIE DANYCH DO PAŃSTW TRZECICH</b></h3>
<p style={{marginBottom:"10px"}}>Twoje dane osobowe mogą być przekazywane do państw trzecich, w związku z wykorzystywaniem przez
nas serwisów społecznościowych, takich jak Facebook, Google+, Tik Tok, itp. lub korzystaniem przez nas
z różnego rodzaju platform reklamowych.</p>
<h3 style={{marginBottom:"10px"}}><b>§8. PRAWA PRZYSŁUGUJĄCE OSOBOM KTÓRYCH DANE SĄ PRZETWARZANE</b></h3>
<p style={{marginBottom:"10px"}}>W przypadku przetwarzania przez nas Twoich danych osobowych, przysługują Ci następujące prawa:</p>
<p style={{marginBottom:"10px"}}>W każdej chwili przysługuje Ci prawo do wniesienia sprzeciwu wobec przetwarzania Twoich danych.
Administrator przestanie przetwarzać Twoje dane, chyba że będzie w stanie wykazać, że istnieją ważne,
prawnie uzasadnione podstawy, które są nadrzędne wobec Twoich interesów, praw i wolności lub  dane te
będą niezbędne do ewentualnego ustalenia, dochodzenia lub obrony roszczeń Administratora.</p>
<p style={{marginBottom:"10px"}}>Ponadto, przysługuje Ci prawo do:</p>
<p style={{marginBottom:"10px"}}>a) dostępu do swoich danych,
b) żądania sprostowania swoich danych,
c) żądania usunięcia, ograniczenia lub wniesienia sprzeciwu wobec ich przetwarzania
d) przenoszenia danych osobowych,
e) wniesienia skargi do organu nadzorczego -na terenie Polski do Prezesa Urzędu Ochrony Danych
Osobowych,
f) jeżeli przetwarzanie odbywa się na podstawie wyrażonej przez Ciebie zgody, masz prawo cofnąć tę
zgodę w każdym czasie bez wpływu na zgodność z prawem przetwarzania, którego dokonaliśmy
na podstawie zgody przed jej cofnięciem.</p>
<p style={{marginBottom:"10px"}}>Podanie danych osobowych jest dobrowolne z tym, że odmowa ich podania może utrudnić albo
uniemożliwić należyte wykonanie umowy i może być podstawą odmowy zawarcia z Tobą umowy lub
przyczyną jej rozwiązania.</p>
<h3 style={{marginBottom:"10px"}}>,<b>§9. PLIKI COOKIES</b></h3>
<p style={{marginBottom:"10px"}}>Wykorzystujemy Pliki cookies w celu zwiększenia funkcjonalności Portalu Internetowego w sposób
opisany w niniejszej Polityce Prywatności.
Pliki Cookies zapisywane są na Twoim urządzeniu i masz możliwość w każdym czasie dokonać zmiany ich
ustawień w swojej przeglądarce internetowej.</p>
      </div>
    </div>
  );
};

export default PageRodo;
