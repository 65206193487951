import React, { FC, useState } from "react";
import Logo from "components/Logo/Logo";
import MenuBar from "components/MenuBar/MenuBar";
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import SwitchDarkMode from "components/SwitchDarkMode/SwitchDarkMode";
import SearchModal from "./SearchModal";
import ReactAnimatedWeather from 'react-animated-weather';
import Button from "../Button/Button";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom"
import LangDropdown from "./LangDropdown"
import LogoSvgMobile from "components/Logo/LogoSvg_mobiile"
import LogoSvg from "components/Logo/LogoSvg"
import LogoMobile from "components/Logo/Logo_mobile"
import { BACKEND_URL } from "constants"

// export interface MainNav1Props {}

const defaults = {
  icon: 'CLEAR_DAY',
  color: 'goldenrod',
  size: 32,
  animate: true
};

const TextButton = ({ onClick, label, activeCategory, style = {} }) => {
  return (
    <button style={{ border: 'none', padding: 0, fontWeight: label === activeCategory ? 700 : 400, ...style}} onClick={onClick}>{label}</button>
  )
} 


const MainNav1 = () => {
  const [hidden,setHidden] = useState(false)
  // const [show,setShow] = useState(false)
  const params = useParams()
  const { pathname } = useLocation();
  const parts = pathname.substring(1).split('/');
  // console.log('parts: ', parts);
  let { category, subcategory, categoryid,  subcategoryid } = params;
  if (!category) {
    category = parts.length === 2 ? parts[0] : parts[1];
  }

  if (!categoryid) {
    categoryid = parts.length === 2 ? parts[1] : parts[2];
  }

  // const [category, setCategory] = useState(params.category || '')
  // const [subcategory, setSubCategory] = useState(params.subcategory || '')
  // const [categoryid, setCategoryId] = useState(params.categoryid || 1)
  // const [subcategoryid, setSubCategoryId] = useState(params.subcategoryid || 1);
  // console.log('pathname: ', category);

  const paths = window.location.pathname.split('/').slice(1);
  console.log(category, "cat")
  return (
    <>
    <Logo/>
    <div className="nc-MainNav1 relative z-10 bg-white dark:bg-slate-900 ">
      <div className="container">
        <div style={{justifyContent:'center', height:"3rem", fontFamily:'poppins'}}className="py-5 flex items-center">
          <div className="flex items-center lg:hidden flex-1">
            <MenuBar />
          </div>
          {/* <div> */}
          {/* <BrowserView> */}
          <div className="menu-responsive">
          <div className="flex items-center space-x-4 sm:space-x-10 2xl:space-x-14">
            <LogoMobile/>
          </div>
          <div style={{display:'flex', gap:"20px", alignItems:"center"}}>
          <Link className="link-font" style={{ fontWeight: paths.includes("swiat") ? 700 : 400, padding:"4px 10px 4px 10px",  backgroundColor:  paths.includes("swiat") && '#ac8502' }} to={`swiat/1`}>ŚWIAT</Link>
        <Link className="link-font"  style={{ fontWeight: paths.includes("kraj") ? 700 : 400, padding:"4px 10px 4px 10px", backgroundColor:  paths.includes("kraj") && '#ac8502' }} to={`kraj/3`}>KRAJ</Link>
        <Link className="link-font"  style={{ fontWeight: paths.includes("region") ? 700 : 400, padding:"4px 10px 4px 10px", backgroundColor:  paths.includes("region") && '#ac8502' }} to={`region/5`}>REGION</Link>
         <Link className="link-font" to="/horoskop">HOROSKOP</Link>
         <Link className="link-font" to="/kuchnia">KUCHNIA</Link>  
         <Link className="link-font" to="/pogoda">POGODA</Link>  
          </div>
          </div>
          <div style={{display:"flex", marginLeft:"auto", alignItems:"center"}}>
           <Link to="/login"><i style={{fontSize:"20px"}} class="fa fa-user" aria-hidden="true"></i></Link>
            <SearchModal/>
           </div>
          <div className="hidden-nav" style={{position:"absolute", top:"74px", fontSize:"12px", height:"24px", width:"100%", justifyContent:"center", color:"white", backgroundColor:"black", right:"1px", gap:"25px", alignItems:"center"}}>
                { category !== undefined && <div style={{fontSize:"12px", backgroundColor:'black', width:"100%", display:'flex', justifyContent:"center", color:"white", gap:"25px", alignItems:"center"}}>
                <Link style={{color: parts[0] === "polityka" ? "#ac8502": "white" }} 
                to={`/polityka/${category}/${categoryid}/2` }
         >POLITYKA</Link>
         <Link style={{color: parts[0] === "gospodarka" ? "#ac8502": "white" }} 
                to={`/gospodarka/${category}/${categoryid}/1`}>GOSPODARKA</Link>

          <Link style={{color: parts[0] === "spoleczenstwo" ? "#ac8502": "white" }} 
                to={`/spoleczenstwo/${category}/${categoryid}/3`}>SPOŁECZEŃSTWO</Link>

          <Link style={{color: parts[0] === "sport" ? "#ac8502": "white" }} 
                to={`/sport/${category}/${categoryid}/4`}>SPORT</Link>
                  {window.location.pathname.split("/")[parts.length === 2 ? 1: 2]  == "region" && <Link style={{color: parts[0] === "aktualnosci" ? "rgb(172, 133, 2)": "white" }} 
                to={parts[0] === "gospodarka" || parts[0] === "polityka" || parts[0] === "spoleczenstwo" || parts[0] === "sport" ||  parts.length === 1 ? `sport/4` :`/aktualnosci/${category}/${categoryid}/5`}>AKTUALNOŚCI</Link>}
                </div>}
          </div>
          {/* </div> */}
          </div>
        </div>
      </div>
      </>
  );
};

export default MainNav1;
