import React, { useState } from "react";
import ButtonPrimary from "components/Button/ButtonPrimary";
import Input from "components/Input/Input";
import Label from "components/Label/Label";
import SocialsList from "components/SocialsList/SocialsList";
import Textarea from "components/Textarea/Textarea";
import PublitioAPI from 'publitio_js_sdk'
import Files from 'react-files'
import Heading2 from "components/Heading/Heading2";
import Layout from "../layout";
import cogoToast from "cogo-toast"
import { BACKEND_URL } from "constants"
import HeaderFilter from "components/Sections/HeaderFilter"

const info = [
  {
    title: "Adres",
    desc: "",
  },
  {
    title: "E-mail",
    desc: "",
  },
  {
    title: "Numer telefonu",
    desc: "",
  },
];

const PageContact = () => {
  const publitio = new PublitioAPI('Bd18D5TejVlG6vhm1lrG', 'ZsDXHq9Jmz9BMxW3encRZ0JD48hVPdfZ')
  const [name,setName] = useState("")
  const [pdf,setPdf] = useState("")
  const [message, setMessage] = useState("")
  const [email,setEmail] = useState("")
  const changeData = (e) => {
  if(e.target.name === "name"){
      setName(e.target.value)
  }
  else if(e.target.name === "message"){
    setMessage(e.target.value)
  }
  else{
      setEmail(e.target.value)
  }
}
const handleChange = (files) => {
setPdf(files[0])
}

const handleError = (error, file) => {
console.log('error code ' + error.code + ': ' + error.message)
}
const sendMail = async() => { 
let data;
  if(name === "" || email === "" ){
      cogoToast.error('Prosze uzupełnić formularz')
  }
  else{
    try {
    data = await publitio.uploadFile(pdf, 'file')
    const payload = {
      name: name,
      email: email,
      message:message,
      cv: data.url_preview
    };

      const response = await fetch(`${BACKEND_URL}/sendMessage`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...payload,
        }),
      });
      const result = await response.json();

      if (result.statusCode === 200) {
        // cogoToast.success(
        //   "Ćwiczenie zostało dodane"
        // );
        alert("Wiadomość pomyślnie została wysłana")
        window.location.reload()
      } else {
        alert("Wystąpił jakiś błąd")
        // cogoToast.error("Nieprawidłowe dane");
      }
    } catch (error) {
      console.error("error: ", error);
    }
  }
}
  return (
    <div className="container">
    <HeaderFilter heading="Kontakt"/>
    <Layout>
      <header className="text-center max-w-2xl mx-auto - mb-14 sm:mb-16 lg:mb-28 ">
        <Heading2>Skontaktuj sie z nami</Heading2>
        <span className="block text-sm mt-2 text-neutral-700 sm:text-base dark:text-neutral-200">
        Napisz do nas, a my skontaktujemy się z Tobą.
        </span>
      </header>

      <div className="grid gap-8 lg:grid-cols-2">
        <div className="max-w-sm space-y-6">
          {info.map((item, index) => (
            <div key={index}>
              <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                {item.title}
              </h3>
              <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                {item.desc}
              </span>
            </div>
          ))}
          <div>
            <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
              Media społecznościowe
            </h3>
            <SocialsList className="mt-2" />
          </div>
        </div>
        <div className="border border-neutral-100 dark:border-neutral-700 lg:hidden"></div>
        <div>
          <form className="grid grid-cols-1 gap-6" action="#" method="post">
            <label className="block">
              <Label>Imie</Label>

              <Input 
              // placeholder="Example Doe"
               type="text" className="mt-1" />
            </label>
            <label className="block">
              <Label>Adres E-mail</Label>

              <Input
                type="email"
                // placeholder="example@example.com"
                className="mt-1"
              />
            </label>
            <label className="block">
              <Label>Załącznik</Label>

              <Files
             style={{background:"#ab850f", cursor:"pointer", textAlign:'center', width:'150px', color:"white",padding:"10px"}}
        className='files-dropzone'
        onChange={handleChange}
        onError={handleError}
        accepts={['image/png', '.pdf', 'audio/*']}
        // multiple
        maxFileSize={10000000}
        minFileSize={0}
        clickable>
          Załącz
      </Files>
            </label>
            <label className="block">
              <Label>Wiadomość</Label>

              <Textarea className="mt-1" rows={6} />
            </label>
            <ButtonPrimary type="submit">Wyślij</ButtonPrimary>
          </form>
        </div>
      </div>
    </Layout>
    </div>
  );
};

export default PageContact;
