import React, { FC, useEffect, useLayoutEffect, useRef } from "react";
import Logo from "components/Logo/Logo";
import MenuBar from "components/MenuBar/MenuBar";
import ReactAnimatedWeather from 'react-animated-weather';
import AvatarDropdown from "./AvatarDropdown";
import SearchModal from "./SearchModal";
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
// import NotifyDropdown from "./NotifyDropdown";
import NavigationGuest from "components/Navigation/NavigationGuest"
import NavigationAdmin from "components/Navigation/NavigationAdmin"
import NavigationReader from "components/Navigation/NavigationReader"
import NavigationUser from "components/Navigation/NavigationUser"
import LangDropdown from "./LangDropdown"
import LogoMobile from "components/Logo/Logo_mobile"
import { Link } from "react-router-dom"

// export interface MainNav2LoggedProps {}

// type UserType = 'users' | 'readers' | 'admins'
// interface SessionUser {
//   statusCode: 200 | 400 | 300
//   type: UserType
//   user: {id: number, name: string, surname: string, email: `${string}@${string}.${string}`, type: UserType}
// }

// const getUserType = () => {
//   const itemUser = sessionStorage.getItem("user");
//     if(!itemUser) {
//       return;
//     }
//     try {
//       const user = JSON.parse(itemUser) as SessionUser
//       if(!user) {
//         return;
//       }
//       console.log(user)

//       return user.type;
//     } catch(error) {
//       console.error('error: ', error);
//     }
// }

const defaults = {
  icon: 'CLEAR_DAY',
  color: 'goldenrod',
  size: 52,
  animate: true
};

const MainNav2AdminLogged = () => {
  // const userRef = useRef(getUserType());
  const user = sessionStorage.getItem("user");

  const renderContent = () => {
  // console.log("user", JSON.parse(user)?.user.id)
    return (
      <div style={{alignItems:"center"}} className="h-20 flex justify-between ">
        <div className="flex items-center lg:hidden flex-1">
          <MenuBar />
        </div>

        <div className="lg:flex-1 flex items-center">
        <MobileView><LogoMobile/></MobileView>
            <BrowserView><Logo/></BrowserView>
        </div>

        <div style={{gap:"20px"}} className="flex-[2] hidden lg:flex justify-center mx-4">
        <Link to="/polityka/2">Polityka</Link>
        <Link to="/gospodarka/1">Gospodarka</Link>
        <Link to="/społeczeństwo/3">Społeczeństwo</Link>
        <Link to="/sport/4">Sport</Link>
        {/* {JSON.parse(user)?.user.type === "admin" ? <NavigationAdmin/>: JSON.parse(user)?.user.type === "reader" ? <NavigationReader/>:JSON.parse(user)?.user.type === "user" ? NavigationUser:<NavigationGuest/>} */}
        </div>
        <div className="flex-1 flex items-center justify-end text-slate-700 dark:text-slate-100">
          <SearchModal />
          {/* <NotifyDropdown /> */}
         <AvatarDropdown />
        </div>
      </div>
    );
  };

  return (
    <div className="nc-MainNav2Logged relative z-10 bg-white dark:bg-neutral-900 border-b border-slate-100 dark:border-slate-700">
      <div className="container ">{renderContent()}</div>
    </div>
  );
};

export default MainNav2AdminLogged;
