import CardCategory1 from "components/CardCategory1/CardCategory1";
import LikeSaveBtns from "components/LikeSaveBtns"
import WidgetHeading1 from "components/WidgetHeading1/WidgetHeading1";
import { DEMO_CATEGORIES } from "data/taxonomies";
import { TaxonomyType } from "data/types";
import React, { FC, useState } from "react";
import ViewerScreenContainer from "ViewerScreenContainer"

const WidgetHeaderCategories = ({
  className = "bg-neutral-100 dark:bg-neutral-800",
  categories,
  live,
  posts,
}) => {
  const [video,setVideo] = useState(false)
  return (
    <div
      className={`nc-WidgetCategories overflow-hidden ${className}`}
    >
      <div className="flow-root">
        <div className="flex flex-col divide-y divide-neutral-200 dark:divide-neutral-700">
          {video === false && <button onClick={() => {
            setVideo(true)}}>Zobacz live</button>}
            {video === true && <button onClick={() => {
            setVideo(false)}}>Wyłącz live</button>}
          {video === true && <ViewerScreenContainer meetingId={live[0]?.url.slice(38,58)} />}
          {posts.filter((_, i) => i < 14 && i >= 10)
          .map((post) => (
            <CardCategory1
              className="p-4 xl:p-5 hover:bg-neutral-200 dark:hover:bg-neutral-700"
              key={post.id}
              id={post.id}
              image={post?.image}
              category={post?.name}
              subcategory={post?.title.toLowerCase()}
              category_id={post?.category_id}
              subcategory_id={post?.subcategory_id}
              name={post?.article_title}
              size="normal"
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default WidgetHeaderCategories;