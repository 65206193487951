import React, { useEffect, useRef, useState } from "react";
// import { DEMO_POSTS_NEWS } from "data/posts";
import SectionMagazine12 from "components/Sections/SectionMagazine12";
import { BACKEND_URL } from "../../../constants";
// import SectionMagazine9 from "components/Sections/SectionMagazine9";
// import SectionAds from "components/Sections/SectionAds";
// import SectionMagazine2 from "components/Sections/SectionMagazine2";
// import SectionMagazine11 from "components/Sections/SectionMagazine11";
// import SectionLatestPosts from "components/Sections/SectionLatestPosts";

//
// const MAGAZINE1_POSTS = DEMO_POSTS_NEWS.filter((_, i) => i >= 8 && i < 16);
// const MAGAZINE2_POSTS = DEMO_POSTS_NEWS.filter((_, i) => i >= 0 && i < 7);
//

const PageGetAllArticles = () => {
  const [data,setData] = useState([])
  let user = sessionStorage.getItem("user")
  const initialized = useRef(false);
  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      fetch(`${BACKEND_URL}/all-articles`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((response) => {
          if (!response.hasOwnProperty("error")) {
           setData(response)
          }
        })
        .catch((error) => {
          console.error("error: ", error);
        })
        .finally(() => {
          console.log("finally")
        })
    }
  }, [JSON.parse(user)?.user.id, user]);
  return (
    <div className="nc-PageHomeDemo6 relative [ nc-section-rounded-md ]">
      <div className="relative overflow-hidden">
        <div className="container relative">
        <h1 style={{marginTop:"50px",marginBottom:"50px"}} className="text-2xl md:text-3xl lg:text-4xl font-semibold">Wszystkie artykuły</h1>
          <SectionMagazine12 posts={data} />
        </div> 
      </div>
    </div>
  );
};

export default PageGetAllArticles;
