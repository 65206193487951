import CardCategory1 from "components/CardCategory1/CardCategory1";
import WidgetHeading1 from "components/WidgetHeading1/WidgetHeading1";
import { DEMO_CATEGORIES } from "data/taxonomies";
import { TaxonomyType } from "data/types";
import React, { FC } from "react";

const WidgetCategoriesSecond = ({
  className = "bg-neutral-100",
  categories,
  live,
  posts,
}) => {
  return (
    <div
      className={`nc-WidgetCategories overflow-hidden ${className}`}
    >
      <div className="flow-root">
        <div style={{paddingTop:"3%", justifyContent:'space-between'}} className="flex container column-box divide-y divide-neutral-200 dark:divide-neutral-700">
          {posts.filter((_, i) => i < 36 && i >= 32)
          .map((post) => {
            return (
            <CardCategory1
              className="p-4 dark:hover:bg-neutral-700 hover:bg-neutral-200"
              key={post.id}
              id={post.id}
              image={post?.image}
              category={post?.name}
              subcategory={post.title.toLowerCase()}
              category_id={post?.category_id}
              subcategory_id={post?.subcategory_id}
              name={post?.article_title}
              size="normal"
            />
          )
          })}
        </div>
      </div>
    </div>
  );
};

export default WidgetCategoriesSecond;
